import React, { useEffect, useState } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd';
import { createflash, deleteflash, fetchflash, updateflash } from '../../store/flashSlice';
import { getSingByIdflash } from '../../store/flashDealProductSlice';
import { useNavigate } from 'react-router-dom';

const FlashDeal = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const { data: flash, status } = useSelector((state) => state?.flash)
    const { data: flashDealProduct, statusF } = useSelector((state) => state?.flashDealProduct)

    useEffect(() => {
        dispatch(fetchflash())

    }, [])

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteflash(id))
            }

        })
    }

    const handleCategory = (values) => {

        if ((document.getElementById(`title`).value.length === 0)) {
            message.error('Must enter title')
        }
        else if ((document.getElementById(`startDate`).value.length === 0)) {
            message.error('Must enter start date')
        }
        else if ((document.getElementById(`endDate`).value.length === 0)) {
            message.error('Must enter end date')
        }
        else {

            const param = new FormData()
            param.append('title', values.title.value)
            param.append('startDate', values.startDate.value)
            param.append('endDate', values.endDate.value)
            param.append('image', values.image.files[0])

            dispatch(createflash(param));
        }

    };

    const [images, setImages] = useState([]);


    const imageHandler = (e) => {
        setImages(e.target.files);
    };

    console.log(images, '==================>upload images')



    const approveFeatured = (id,featured) => {
        Modal.confirm({
            title: 'Are you sure you want to set this flash deal?',
            onOk: () => {

                const param=new FormData();
                param.append('publish', true);
                

                dispatch(updateflash(id,param))
            }

        })
    }




    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            <div className="content container-fluid mt-5" >;

                <div>
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/flash_deal.png"
                            alt=""
                        />
                        Flash deals
                    </h2>

                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className=" lang_form" id="en-form">
                                        <div className="form-group">
                                            <label
                                                htmlFor="name"
                                                className="title-color font-weight-medium text-capitalize"
                                            >
                                                Title (EN)
                                            </label>
                                            <input
                                                type="text"
                                                name="title"
                                                className="form-control"
                                                id="title"
                                                placeholder="Ex : LUX"
                                                required=""
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Start date
                                        </label>
                                        <input
                                            type="date"
                                            name="startDate"
                                            id="startDate"
                                            required=""
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            End date
                                        </label>
                                        <input type="date" name="endDate" id="endDate" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                                        <div className="form-group">

                                            {images.length > 0 ?
                                                <center>
                                                    <div className='position-relative'>
                                                        <img src={URL.createObjectURL(images[0])} className='border rounded-3' />
                                                        <span className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white' style={{ left: '98%', lineHeight: '15px', fontSize: 'x-small', cursor: 'pointer' }} onClick={() => {
                                                            setImages([])
                                                        }}>x</span>
                                                    </div>
                                                </center> :
                                                <center>
                                                    <img
                                                        className="upload-img-view"
                                                        id="viewer"
                                                        src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                                        alt="image"
                                                    />
                                                </center>}

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor="name"
                                            className="title-color font-weight-medium text-capitalize"
                                        >
                                            Upload image
                                        </label>
                                        <span className="text-info ml-1">( Ratio 5:1 )</span>
                                        <div className="custom-file text-left">
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                className="custom-file-input"
                                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"


                                                onChange={(e) => imageHandler(e)}
                                            />
                                            <label
                                                className="custom-file-label text-capitalize"
                                                htmlFor="customFileUpload"
                                            >
                                                Choose file
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>

                        </div>
                    </form>

                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="mb-0 text-capitalize d-flex gap-2">
                                                Flash deal table
                                                <span className="badge badge-soft-dark radius-50 fz-12">4</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Title</th>
                                                <th>Duration</th>
                                                <th>Status</th>
                                                {/* <th>Active products</th> */}
                                                <th>Publish</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {flash?.length > 0 ? flash && flash?.map((i, index) => {
                                                return (<>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <span className="font-weight-semibold">{i?.title}</span>
                                                        </td>
                                                        <td>{new Date(i?.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })} - {new Date(i?.endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}</td>
                                                        <td>
                                                            <span className="badge badge-soft-danger">Expired </span>
                                                        </td>
                                                        {/* <td>{
                                                        }</td> */}
                                                        <td>
                                                            <label className="switcher">
                                                                {i?.publish === true ? <input
                                                                    className="switcher_input"
                                                                    type="checkbox"
                                                                    onClick={() => approveFeatured(i?.id, i?.publish === false ? true : false)}
                                                                    defaultChecked
                                                                /> : <input
                                                                    className="switcher_input"
                                                                    type="checkbox"
                                                                    onClick={() => approveFeatured(i?.id, i?.publish === false ? true : false)}

                                                                />}
                                                                <span className="switcher_control" />
                                                            </label>
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="d-flex align-items-center justify-content-center gap-3">

                                                                <button
                                                                    title="Delete"
                                                                    className="btn btn-outline-danger btn-sm"
                                                                    onClick={() => { onDelete(i.id) }}
                                                                >
                                                                    <i className="bi bi-trash" />
                                                                </button>
                                                                <a
                                                                    className="h-30 d-flex gap-2 align-items-center btn btn-soft-info btn-sm border-info"
                                                                    onClick={() => navigate(`/flashDealProduct/${i?.id}`)}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={9}
                                                                        height={9}
                                                                        viewBox="0 0 9 9"
                                                                        fill="none"
                                                                        className="svg replaced-svg"
                                                                    >
                                                                        <path
                                                                            d="M9 3.9375H5.0625V0H3.9375V3.9375H0V5.0625H3.9375V9H5.0625V5.0625H9V3.9375Z"
                                                                            fill="#00A3AD"
                                                                        />
                                                                    </svg>
                                                                    Add Product
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>)
                                            }) : ''}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive mt-4">
                                    <div className="px-4 d-flex justify-content-lg-end"></div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>


        </div>
    )
}

export default FlashDeal