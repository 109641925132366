import React, { useEffect, useState } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd';
import { createsubCategory, deletesubCategory, fetchsubCategory,updatesubCategory,getByIdsubCategory } from '../../store/subCategorySlice';
import { fetchcategory } from '../../store/categorySlice';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';

const UpdateSubCate = () => {
    const {id}=useParams()
    const dispatch = useDispatch()
    const { data: subCategory, statusSub } = useSelector((state) => state?.subCategory)
    const { data: category, status } = useSelector((state) => state?.category)
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // dispatch(fetchcategory())
        dispatch(getByIdsubCategory(id));
    }, [])
// console.log(statusSub,"00000000000000000000000000000000000000")
    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    



 

    const handleCategory = (values) => {

        if ((document.getElementById(`name`).value.length === 0)) {
            message.error('Must enter name')
        }
      
        else {
            const param = {
                "name":values.name.value
               

            }
    setLoading(true)
            dispatch(updatesubCategory(id,param));
        }
    };


    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>{loading?<Loader/>:null}
            <div className="content container-fluid mt-5" >
                <div>
                    <h2 className="h1 mb-0 d-flex gap-10">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png"
                            alt=""
                        />
                        Sub Category Setup
                    </h2>

                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group  lang_form" id="en-form">
                                        <label className="title-color" htmlFor="exampleFormControlInput1">
                                            Sub category Name<span className="text-danger">*</span>
                                            (EN)
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="form-control"
                                            placeholder="New Sub Category"
                                            required=""
                                            defaultValue={subCategory?.name}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="title-color" htmlFor="exampleFormControlSelect1">
                                            Main Category
                                            <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            name="categoryId"
                                            id="categoryId"
                                            className="form-control"
                                            required=""
                                        >
                                            {category?.length>0?category && category?.map((i, index) => {
                                                return (<>
                                                    <option value={i.id}>{i.name}</option>
                                                </>)
                                            }):""}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    {/* <div className="form-group">
                                        <label className="title-color" htmlFor="priority">
                                            Priority
                                            <span>
                                                <i
                                                    className="tio-info-outined"
                                                    title="The lowest number will get the highest priority"
                                                />
                                            </span>
                                        </label>
                                        <select className="form-control" name="priority" id="priority" required=""   >
                                        
                                        <option disabled>
                                            Set Priority
                                        </option>
                                        <option value='0'>0</option>
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                        <option value='3'>3</option>
                                        <option value='4'>4</option>
                                        <option value='5'>5</option>
                                        <option value='6'>6</option>
                                        <option value='7'>7</option>
                                        <option value='8'>8</option>
                                        <option value='9'>9</option>
                                        <option value='10'>10</option>
                                        defaultValue={ subCategory?.priority}
                                    </select>
                                    </div> */}
                                    <div className="d-flex flex-wrap gap-2 justify-content-end mt-4">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                                </div>
                            </div>


                           

                        </div>
                    </form>
             

                </div>
            </div>


        </div>
    )
}

export default UpdateSubCate;
