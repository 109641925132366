import logo from './logo.svg';
import './App.css';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import OutRoutes from './pages/OutRoutes';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import AllOrder from './pages/orders/AllOrder';
import Pending from './pages/orders/Pending';
import ProcessOrder from './pages/orders/ProcessOrder';
import ConfirmedOrder from './pages/orders/ConfirmedOrder';
import OutDelivery from './pages/orders/OutDelivery';
import Delivered from './pages/orders/Delivered';
import Return from './pages/orders/Return';
import Failed from './pages/orders/Failed';
import Cancel from './pages/orders/Cancel';
import AddBrand from './pages/brands/AddBrand';
import ListBrand from './pages/brands/ListBrand';
import NewProducts from './pages/sellerProducts/NewProducts';
import ApprovedProducts from './pages/sellerProducts/ApprovedProducts';
import DeniedProducts from './pages/sellerProducts/DeniedProducts';
import Category from './pages/category/Category';
import SubCategory from './pages/category/SubCategory';
import SubSubCategory from './pages/category/SubSubCategory';
import Products from './pages/inhouseProducts/Products';
import AddProduct from './pages/inhouseProducts/AddProduct';
import Banners from './pages/banners/Banners';
import AddBanner from './pages/banners/AddBanner';
import FlashDeal from './pages/deals/FlashDeal';
import DealDay from './pages/deals/DealDay';
import FeaturedDeal from './pages/deals/FeaturedDeal';
import CustomerList from './pages/customer/CustomerList';
import SellerList from './pages/sellers/SellerList';
import AddSeller from './pages/sellers/AddSeller';
import SellerSales from './pages/sellerReport/SellerSales';
import InhouseSale from './pages/sellerReport/InhouseSale';
import AdminWallet from './pages/Wallet/AdminWallet';
import ShippingCharges from './pages/shipping/ShippingCharges';
import Sms from './pages/SMS/Sms';
import StockProduct from './pages/inhouseProducts/StockProduct';
import FlashDealProduct from './pages/deals/FlashDealProduct';
import FeatureDealProduct from './pages/deals/FeatureDealProduct';
import Withdraw from './pages/Withdrawals/Withdraw';
import WithdrawPending from './pages/Withdrawals/WithdrawPending';
import WithdrawDenied from './pages/Withdrawals/WithdrawDenied';

import UpdateBrand from './pages/brands/UpdateBrand';
import UpdateCategorey from './pages/UpdateCategory/UpdateCategory';
import UpdateSubCate from './pages/UpdateCategory/UpdateSubCate';
import UpdateSubSubCate from './pages/UpdateCategory/UpdateSubSubCate';
import CustomerWallet from './pages/customer/CustomerWallet';
import UpdateProduct from './pages/UpdateProducts/UpdateProduct';
import UpdateBanner from './pages/banners/UpdateBanner';
import UpdateSeller from './pages/sellers/UpdateSeller';

function App() {
  return (
    <div className="App">
      <HashRouter>
      <Routes>
        <Route path='/' element={<Login/>} />
        <Route element={<OutRoutes/>}>
          <Route path='/dashboard' element={<Dashboard/>}/>

  
          <Route path='/UpdateSeller/:id' element={<UpdateSeller/>}/>
          <Route path='/UpdateBanner/:id' element={<UpdateBanner/>}/>
          <Route path='/UpdateProduct/:id' element={<UpdateProduct/>}/>
          <Route path='/UpdateSubSubCategorey/:id' element={<UpdateSubSubCate/>}/>
          <Route path='/UpdateCategorey/:id' element={<UpdateCategorey/>}/>
          <Route path='/UpdateSubCate/:id' element={<UpdateSubCate/>}/>
          <Route path='/UpdateBrand/:id' element={<UpdateBrand/>}/>
          <Route path='/customerWallet/:id' element={<CustomerWallet/>}/>

          <Route path='/allOrder' element={<AllOrder/>}/>
          <Route path='/pendingOrder' element={<Pending/>}/>
          <Route path='/processOrder' element={<ProcessOrder/>}/>
          <Route path='/confirmedOrder' element={<ConfirmedOrder/>}/>
          <Route path='/outDeliveryOrder' element={<OutDelivery/>}/>
          <Route path='/deliveredOrder' element={<Delivered/>}/>
          <Route path='/returnOrder' element={<Return/>}/>
          <Route path='/failedOrder' element={<Failed/>}/>
          <Route path='/cancelOrder' element={<Cancel/>}/>


          <Route path='/addBrand' element={<AddBrand/>}/>
          <Route path='/brand' element={<ListBrand/>}/>


          <Route path='/newSellerProduct' element={<NewProducts/>}/>
          <Route path='/approvedSellerProduct' element={<ApprovedProducts/>}/>
          <Route path='/deniedSellerProduct' element={<DeniedProducts/>}/>


          <Route path='/category' element={<Category/>}/>
          <Route path='/subCategory' element={<SubCategory/>}/>
          <Route path='/subSubCategory' element={<SubSubCategory/>}/>


          <Route path='/products' element={<Products/>}/>
          <Route path='/addProducts' element={<AddProduct/>}/>
          <Route path='/stockProduct' element={<StockProduct/>}/>



          <Route path='/banners' element={<Banners/>}/>
          <Route path='/addBanners' element={<AddBanner/>}/>



          <Route path='/flashDeal' element={<FlashDeal/>}/>
          <Route path='/flashDealProduct/:id' element={<FlashDealProduct/>}/>
          <Route path='/featureDealProduct/:id' element={<FeatureDealProduct/>}/>
          <Route path='/featuredDeal' element={<FeaturedDeal/>}/>
          <Route path='/dealDay' element={<DealDay/>}/>


          <Route path='/customer' element={<CustomerList/>}/>

          <Route path='/sellerList' element={<SellerList/>}/>
          <Route path='/addSeller' element={<AddSeller/>}/>


          <Route path='/sellerSale' element={<SellerSales/>}/>
          <Route path='/inhouseSale' element={<InhouseSale/>}/>


          <Route path='/adminWallet' element={<AdminWallet/>}/>


          <Route path='/shippingCharges' element={<ShippingCharges/>}/>

          <Route path='/sms' element={<Sms/>}/>



          <Route path='/withdrawsApproved' element={<Withdraw/>}/>
          <Route path='/withdrawsPending' element={<WithdrawPending/>}/>
          <Route path='/withdrawsDenied' element={<WithdrawDenied/>}/>


        </Route>
      </Routes>
      
      </HashRouter>
    </div>
  );
}

export default App;
