import React, { useEffect } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd';
import { deletebrand, fetchbrand } from '../../store/brandSlice';
import { useHistory,useNavigate } from 'react-router-dom';
import UpdateBrand from './UpdateBrand';
const ListBrand = () => {

    const dispatch = useDispatch()
    const { data: brand, status } = useSelector((state) => state?.brand)

    useEffect(() => {
        dispatch(fetchbrand())
    }, [])
    const navigate = useNavigate();
    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const onDelete = (_id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deletebrand(_id))
            }

        })
    }




    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-0 d-flex gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/brand.png"
                            alt=""
                        />
                        Brand List
                        <span className="badge badge-soft-dark radius-50 fz-14">13</span>
                    </h2>
                </div>
                <div className="row mt-20">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Brand Logo</th>
                                                <th>Name</th>
                                                <th>Total Product</th>
                                                {/* <th>Total Order</th>
                                                <th className="text-center">Status</th> */}
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {brand.length > 0 ? brand && brand?.map((i, index) => {
                                                return (<>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {i?.image && (
                                                                <td>
                                                                    <img src={i?.image} alt="Image" width='35px' height='35px' />
                                                                </td>
                                                            )}
                                                        </td>
                                                        <td>{i.name}</td>
                                                        <td>{i.product.length}</td>
                                                        {/* <td>{}</td> */}
                                                        {/* <td>
                                                            <label className="switcher mx-auto">
                                                                <input
                                                                    type="checkbox"
                                                                    className="status switcher_input"
                                                                    id={17}
                                                                    defaultChecked=""
                                                                />
                                                                <span className="switcher_control" />
                                                            </label>
                                                        </td> */}
                                                        <td>
                                                            <div className="d-flex justify-content-center gap-2">
                                                            <button
                                                               className=" btn btn-outline-success square-btn btn-sm mr-1"
                                                      onClick={()=> navigate('/UpdateBrand/'+i?.id)}
                                                                                  >
                                                                 <i className="bi bi-pencil" />
                                                           </button>
                                                                <button
                                                                    onClick={() => onDelete(i?.id)}
                                                                    className=" btn btn-outline-danger square-btn btn-sm mr-1"
                                                                    title="Delete">
                                                                    <i className="bi bi-trash" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </>)
                                            }) : ''}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ListBrand
