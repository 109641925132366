import React,{useEffect, useState} from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { fetchsellerSale } from '../../store/sellerSaleSlice';
import { api_url } from '../../ApiUrl';
import axios from 'axios';


const SellerSales = () => {

    const [comProduct, setComProduct] = useState([])

    useEffect( () => {
        try {
          axios.get(`${api_url}/saleproduct/get`).then((res) => {
            console.log("=====all sale Product===", res.data)
            setComProduct(res.data.data)
          })
        } catch (error) {
          console.log(error)
        }
      },[])
    
    
    
      function separateArraysByProductId(arr) {
        const separatedArrays = [];
    
        // Create a map to store arrays of objects by productId
        const map = new Map();
    
        // Iterate over the array and group objects by productId
        for (const obj of arr) {
          const productId = obj.productId;
    
          if (map.has(productId)) {
            // If productId already exists in the map, push the object to the existing array
            const existingArray = map.get(productId);
            existingArray.push(obj);
          } else {
            // Otherwise, create a new array and add the object to the map
            map.set(productId, [obj]);
          }
        }
    
        // Iterate over the map and push arrays to the result array
        for (const objArray of map.values()) {
          separatedArrays.push(objArray);
        }
    
        return separatedArrays;
      }
    
      const sellerSale = separateArraysByProductId(comProduct);

      console.log(sellerSale,'aghfsahsa')




    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });



    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/seller_sale.png"
                            alt=""
                        />
                        Seller sale
                    </h2>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <form >
                                    <input
                                        type="hidden"
                                        name="_token"
                                        defaultValue="cj2nMV9M6ekEkV26grL9W3t43zCNVxr8zOFR2LKG"
                                    />{" "}
                                    <div className="row gy-2">
                                        <div className="col-sm-6 col-md-5">
                                            <div className="d-flex align-items-center gap-10">
                                                <label
                                                    htmlFor="exampleInputEmail1"
                                                    className="title-color mb-0"
                                                >
                                                    Seller
                                                </label>
                                                <select
                                                    className="js-select2-custom form-control select2-hidden-accessible"
                                                    name="seller_id"
                                                    data-select2-id={1}
                                                    tabIndex={-1}
                                                    aria-hidden="true"
                                                >
                                                    <option value="all" data-select2-id={3}>
                                                        All
                                                    </option>
                                                    
                                                </select>
                                                <span
                                                    className="select2 select2-container select2-container--default"
                                                    dir="ltr"
                                                    data-select2-id={2}
                                                    style={{ width: "100%" }}
                                                >
                                                    <span className="selection">
                                                        <span
                                                            className="select2-selection custom-select"
                                                            role="combobox"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            tabIndex={0}
                                                            aria-disabled="false"
                                                            aria-labelledby="select2-seller_id-f2-container"
                                                        >
                                                            <span
                                                                className="select2-selection__rendered"
                                                                id="select2-seller_id-f2-container"
                                                                role="textbox"
                                                                aria-readonly="true"
                                                                title="All"
                                                            >
                                                                
                                                            </span>
                                                            <span
                                                                className="select2-selection__arrow"
                                                                role="presentation"
                                                            >
                                                                <b role="presentation" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <span className="dropdown-wrapper" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5">
                                            <div className="d-flex align-items-center gap-10">
                                                <label
                                                    htmlFor="exampleInputEmail1"
                                                    className="title-color mb-0"
                                                >
                                                    Category
                                                </label>
                                                <select
                                                    className="js-select2-custom form-control w-100 select2-hidden-accessible"
                                                    name="category_id"
                                                    data-select2-id={14}
                                                    tabIndex={-1}
                                                    aria-hidden="true"
                                                >
                                                    <option value="all" data-select2-id={16}>
                                                        All
                                                    </option>
                                                    
                                                </select>
                                                <span
                                                    className="select2 select2-container select2-container--default"
                                                    dir="ltr"
                                                    data-select2-id={15}
                                                    style={{ width: "100%" }}
                                                >
                                                    <span className="selection">
                                                        <span
                                                            className="select2-selection custom-select"
                                                            role="combobox"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            tabIndex={0}
                                                            aria-disabled="false"
                                                            aria-labelledby="select2-category_id-kp-container"
                                                        >
                                                            <span
                                                                className="select2-selection__rendered"
                                                                id="select2-category_id-kp-container"
                                                                role="textbox"
                                                                aria-readonly="true"
                                                                title="All"
                                                            >
                                                                
                                                            </span>
                                                            <span
                                                                className="select2-selection__arrow"
                                                                role="presentation"
                                                            >
                                                                <b role="presentation" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <span className="dropdown-wrapper" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <button type="submit" className="btn btn-primary btn-block">
                                                Filter
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="table-responsive" style={{ textAlign: "left" }}>
                                <table 
                                id='allOrder'
                                className="table table-hover table-borderless table-thead-bordered table-align-middle card-table w-100">
                                    <thead className="thead-light thead-50 text-capitalize table-nowrap">
                                        <tr>
                                            <th>SL </th>
                                            <th>Product Name</th>
                                            <th className="text-center">Total Sale</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {sellerSale?.length>0?sellerSale && sellerSale?.map((i, index) => {
                                        if(i[0]?.product?.addedAs==='seller'){
                                            return (<>
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>{i[0]?.product?.name}</td>
                                            <td className="text-center">{i.length}</td>
                                        </tr>
                                        </>)
                                        }
                                        }):''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SellerSales
