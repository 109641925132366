import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const subSubCategorySlice=createSlice({
    name: "subSubCategory",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setsubSubCategory(state,action){
            state.data=action.payload
        },
        getsubSubCategory(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setsubSubCategory,setStatus,getsubSubCategory}=subSubCategorySlice.actions
export default subSubCategorySlice.reducer




// thunk

export function fetchsubSubCategory(){
    return async function fetchsubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/subSubCategory/get`).then(
                (resp) => {
                    console.log(resp.data)
                    dispatch(setsubSubCategory(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createsubSubCategory(param){
    return async function createsubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/subSubCategory/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("subSubCategory Created")
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatesubSubCategory(id,param){
    return async function updatesubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/subSubCategory/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("subSubCategory Updated")
                
                    window.location.href=window.location.origin+"/#/subSubcategory"
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletesubSubCategory(id){
    return async function deletesubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/subSubCategory/delete/${id}`).then(res=>{
                console.log(res.data)
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdsubSubCategory(id){
    return async function getByIdsubSubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/subSubCategory/get/${id}`).then(
                (resp) => {
                    dispatch(getsubSubCategory(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
