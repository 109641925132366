import { message } from 'antd'
import axios from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { api_url } from '../ApiUrl'

const Login = () => {
    const navigate=useNavigate()

    const submit=(e)=>{
        e.preventDefault()
        if(document.getElementById('email').value==='' || document.getElementById('password').value===''){
            message.error('Please Fill Empty Fields')
        }else{
            const params = {
            "email":document.getElementById('email').value,
            "password":document.getElementById('password').value,
            }
            axios.post(`${api_url}/staff/login`, params).then((res)=>{
                if(res.data.status === "ok"){
                    localStorage.setItem("staffId", res.data.data.id)
                    message.success('Login Successfully')
                    navigate("/dashboard")
                }else{
                    message.error("Invalid Credentials")
                }
            })
        }
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="authentication-card">
                    <div className="card shadow rounded-0 overflow-hidden">
                        <div className="row g-0">
                            <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                                <img
                                    src={require('./login-img.e4f581571071e48ee5ac.jpg')}
                                    className=" m-0 p-0"
                                    width='100%'
                                    height='100%'
                                    alt="logo"
                                />
                            </div>
                            <div className="col-lg-6">
                                <div className="card-body p-4 p-sm-5">
                                    <h5 className="card-title">Sign In</h5>
                                    <p className="card-text mb-5">
                                        See your growth and get consulting support!
                                    </p>
                                    <form className="form-body">
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <label htmlFor="inputEmailAddress" className="form-label">
                                                    Email
                                                </label>
                                                <div className="ms-auto position-relative">
                                                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                                        <i className="bi bi-envelope-fill" />
                                                    </div>
                                                    <input
                                                        type="email"
                                                        className="form-control radius-30 ps-5"
                                                        id="email"
                                                        placeholder="Enter your Email"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="inputChoosePassword" className="form-label">
                                                    Enter Password
                                                </label>
                                                <div className="ms-auto position-relative">
                                                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                                        <i className="bi bi-lock-fill" />
                                                    </div>
                                                    <input
                                                        type="password"
                                                        className="form-control radius-30 ps-5"
                                                        id="password"
                                                        placeholder="Enter Password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckChecked"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexSwitchCheckChecked"
                                                    >
                                                        Remember Me
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <div className="col-6 text-end">
                                                {" "}
                                                <a href="authentication-forgot-password.html">
                                                    Forgot Password ?
                                                </a>
                                            </div> */}
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    <button type="submit" className="btn btn-primary radius-30" onClick={submit}>
                                                        Sign In
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <div className="col-12">
                                                <p className="mb-0">
                                                    Don't have an account yet? <a href="">Sign up here</a>
                                                </p>
                                            </div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login