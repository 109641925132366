import React,{useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd';

import { createshippingCharges, deleteshippingCharges, fetchshippingCharges } from '../../store/shippingChargesSlice';

const Sms = () => {

    const dispatch = useDispatch()

    
    const handleCategory = (values) => {

        if ((document.getElementById(`weight`).value.length === 0)) {
            message.error('Must enter weight')
        }
        else if ((document.getElementById(`price`).value.length === 0)) {
            message.error('Must select price')
        }
        else {

            dispatch(createshippingCharges({
                weight: values.weight.value,
                price: values.price.value,
            }));
        }

    };





    return (
        <div>
            <div className='mt-5 toppp'>
                <div className="content container-fluid mt-5">
                    <div className="mb-3">
                        <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                            <img src="https://6valley.6amtech.com/public/assets/back-end/img/3rd-party.png" alt=""/>
                                3rd party
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card p-3">
                                <div className="card-header">
                                    <h5 className="mb-0">Releans sms</h5>
                                </div>
                                <div className="card-body text-left">
                                    <span className="badge text-wrap badge-soft-info mb-3">
                                        NB : #OTP# will be replace with otp
                                    </span>
                                    <form action="javascript:" style={{ textAlign: "left" }} method="post">
                                        <input
                                            type="hidden"
                                            name="_token"
                                            defaultValue="PiVTsmfUxC711N9n3jnEWtI0SChwFEOBA9JrcWu1"
                                        />
                                        <label className="mb-3 d-block title-color">Releans sms</label>
                                        <div className="d-flex gap-10 align-items-center mb-2">
                                            <input type="radio" name="status" defaultValue={1} />
                                            <label className="title-color mb-0">Active</label>
                                        </div>
                                        <div className="d-flex gap-10 align-items-center mb-4">
                                            <input type="radio" name="status" defaultValue={0} />
                                            <label className="title-color mb-0">Inactive </label>
                                        </div>
                                        <div className="form-group">
                                            <label className="title-color">Api key</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="api_key"
                                                defaultValue=""
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="title-color">From</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="from"
                                                defaultValue=""
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="title-color">Otp template</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="otp_template"
                                                defaultValue=""
                                            />
                                        </div>
                                        <div className="mt-3 d-flex flex-wrap justify-content-end gap-10">
                                            <button
                                                type="button"
                                                onclick="call_demo()"
                                                className="btn btn-primary px-4"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Sms
