import React, { useEffect, useState } from 'react'
import { useHistory,useNavigate } from 'react-router-dom';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
// import Modals from '../Modals/Modals';
import UpdateCategorey from '../UpdateCategory/UpdateCategory';

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import {Modal, message } from 'antd';
import { createcategory, deletecategory, fetchcategory,updatecategory ,getByIdcategory} from '../../store/categorySlice';
import Loader from '../Loader';



const Category = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { data: category, status } = useSelector((state) => state?.category)

    useEffect(() => {
        dispatch(fetchcategory())
    }, [])

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });




    const [images, setImages] = useState([]);


    const imageHandler = (e) => {
        setImages(e.target.files);
    };

    console.log(images, '==================>upload images')









    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deletecategory(id))
            }

        })
    }

const [loading, setLoading] = useState(false);



    const handleCategory = (values) => {

        if ((document.getElementById(`name`).value.length === 0)) {
            message.error('Must enter name')
        }
        else if ((document.getElementById(`priority`).value.length === 0)) {
            message.error('Must enter priority')
        }
        else {
setLoading(true)
            const param = new FormData()
            param.append('name', values.name.value)
            param.append('priority', values.priority.value)
            param.append('adminComission', values.adminComission.value)
            param.append('image', images[0])

            dispatch(createcategory(param));
        }

    };


   




    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>{loading?<Loader/>:null}
            <div className="content container-fluid mt-5" >
                <div>
                    <h2 className="h1 mb-0 d-flex gap-10">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png"
                            alt=""
                        />
                        Category Setup
                    </h2>
  
                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div>
                                        <div className="form-group  lang_form" id="en-form">
                                            <label className="title-color">
                                                Category Name<span className="text-danger">*</span> (EN)
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="form-control"
                                                placeholder="New Category"
                                                required=""
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-group  lang_form" id="en-form">
                                            <label className="title-color">
                                                Admin Commission<span className="text-danger">*</span> (EN)
                                            </label>
                                            <input
                                                type="text"
                                                name="adminComission"
                                                id="adminComission"
                                                className="form-control"
                                                placeholder="New Category"
                                                required=""
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="title-color" htmlFor="priority">
                                            Priority
                                            <span>
                                                <i
                                                    className="tio-info-outined"
                                                    title="The lowest number will get the highest priority"
                                                />
                                            </span>
                                        </label>
                                        <select className="form-control" name="priority" id="priority" required="">
                                            <option disabled>
                                                Set Priority
                                            </option>
                                            <option value='0'>0</option>
                                            <option value='1'>1</option>
                                            <option value='2'>2</option>
                                            <option value='3'>3</option>
                                            <option value='4'>4</option>
                                            <option value='5'>5</option>
                                            <option value='6'>6</option>
                                            <option value='7'>7</option>
                                            <option value='8'>8</option>
                                            <option value='9'>9</option>
                                            <option value='10'>10</option>
                                        </select>
                                    </div>
                                    <div className="from_part_2">
                                        <label className="title-color">Category Logo</label>
                                        <span className="text-info">
                                            <span className="text-danger">*</span> ( Ratio 1:1 )
                                        </span>
                                        <div className="custom-file text-left">
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                className="custom-file-input"
                                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                                required=""
                                                onChange={(e)=>imageHandler(e)}
                                            />
                                            <label className="custom-file-label" htmlFor="customFileEg1">
                                                Choose File
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                                    <div className="form-group">

                                    {images.length>0 ?
                                    <center>
                                            <div className='position-relative'>
                                                <img src={URL.createObjectURL(images[0])}  className='border rounded-3' />
                                                <span className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white' style={{ left: '98%', lineHeight: '15px', fontSize: 'x-small', cursor: 'pointer' }} onClick={() => {
                                                    setImages([])
                                                }}>x</span>
                                            </div>
                                            </center>:
                                    <center>
                                            <img
                                                className="upload-img-view"
                                                id="viewer"
                                                src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                                alt="image"
                                            />
                                        </center>}
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>

                        </div>
                    </form>

                    <div className="row mt-3" id="cate-table">
                    
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="text-capitalize d-flex gap-1">
                                                Category list
                                                <span className="badge badge-soft-dark radius-50 fz-12">13</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th className="text-center">Category Image</th>
                                                <th>Name</th>
                                                <th>Priority</th>
                                                {/* <th className="text-center">Home category status</th> */}
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {category?.length > 0 ? category && category?.map((i, index) => {
                                                return (<>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td className="text-center">
                                                            <img
                                                                className="rounded"
                                                                width={64}
                                                                src={i.image}
                                                            />
                                                        </td>
                                                        <td>{i.name}</td>
                                                        <td>{i.priority}</td>
                                                        {/* <td className="text-center">
                                                            <label className="switcher mx-auto">
                                                                <input
                                                                    type="checkbox"
                                                                    className="switcher_input category-status"
                                                                    id={39}
                                                                />
                                                                <span className="switcher_control" />
                                                            </label>
                                                        </td> */}
                                                     
                                                        <td>
                                                            <div className="d-flex justify-content-center gap-2">
                                                            <button
                                                               className=" btn btn-outline-success square-btn btn-sm mr-1"
                                                      onClick={()=> navigate('/UpdateCategorey/'+i?.id)}
                                                                                  >
                                                                 <i className="bi bi-pencil" />
                                                           </button>

                                                                
                                                                <button
                                                                    onClick={() => onDelete(i.id)}
                                                                    className=" btn btn-outline-danger square-btn btn-sm mr-1"
                                                                    title="Delete" >
                                                                    <i className="bi bi-trash" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </>)
                                            }) : ''}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive mt-4">
                                    <div className="d-flex justify-content-lg-end"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default Category