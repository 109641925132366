import React, { useEffect, useState } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd';

import axios from 'axios';
import { api_url } from '../../ApiUrl';
import { useParams } from 'react-router-dom';


const CustomerWallet = () => {
    const [updateWallet, setUpdateWallet] = useState()
    const [myWallet, setMyWallet] = useState()

    const { id } = useParams()


    const getAmount = () => {

        axios.get(`${api_url}/userWallet/getSingle/${id}`).then((res) => {
            console.log(res.data, "------------------------------");

            setMyWallet(res.data.data)
        })
    }




    const getUpdate = () => {


        const param = {
            'credit': document.getElementById('newcredit').value,
            'status': true
        }

        axios.put(`${api_url}/userWallet/update/${updateWallet?.id}`, param).then((res) => {
            console.log(res.data, "------------------------------");
            if (res.data.status === 'ok') {
                message.success('Updated')
                window.location.reload(true)
            }

            //   setMyWallet(res.data.data)
        })
    }


    $(document).ready(function () {
        setTimeout(function () {
            $('#customerList').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                axios.delete(`${api_url}/users/delete/${id}`).then((res) => {
                    if (res.data.status === 'ok') {
                        message.success('Deleted successfully')
                    }
                })
            }

        })
    }


    useEffect(() => {
        getAmount()
    }, [])




    return (

        <div className='mt-5 toppp'>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Wallet</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <div className='Moodal' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    width={150}
                                    src={updateWallet?.image}
                                    alt=""
                                />
                                <label>Amount</label>
                                <input placeholder='Enter Amount' id='newcredit' defaultValue={updateWallet?.credit} />

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={getUpdate}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content container-fluid mt-5">
                <div className="mb-4">
                    <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/customer.png"
                            alt=""
                        />
                        Customer Wallet
                        <span className="badge badge-soft-dark radius-50">8</span>
                    </h2>
                </div>
                <div className="card p-4">
                    <div className="table-responsive datatable-custom">
                        <table
                            style={{ textAlign: "left" }}
                            className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                            id='customerList'
                        >
                            <thead className="thead-light thead-50 text-capitalize">
                                <tr>
                                    <th>SL</th>
                                    <th>Image</th>
                                    <th>Credit</th>
                                    <th>Status</th>

                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {myWallet?.map((i, index) => {
                                    return (<>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                                {i?.image && (
                                                    <td>
                                                        <img src={i?.image} alt="Image" width='35px' height='35px' />
                                                    </td>
                                                )}
                                            </td>
                                            
                                            <td>


                                                {i?.credit}



                                            </td>

                                            <td>
                                                {i?.status?'Approved':'Pending'}
                                            </td>

                                            <td>
                                                <div className="d-flex justify-content-center gap-2">
                                                    <button
                                                        title="Edit"
                                                        className="btn btn-outline-info btn-sm square-btn"
                                                        data-bs-target="#exampleModal"
                                                        data-bs-toggle="modal"
                                                        onClick={() => {
                                                            setUpdateWallet(i.id)

                                                            axios.get(`${api_url}/userWallet/get/${i.id}`).then((res) => {
                                                                console.log(res.data, "------------------------------");

                                                                setUpdateWallet(res.data.data)
                                                            })

                                                        }}
                                                    >
                                                        <i className="bi bi-pencil" />

                                                    </button>

                                                </div>
                                            </td>
                                        </tr>

                                    </>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CustomerWallet
