import React, { useEffect, useState } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { api_url } from '../../ApiUrl';


const InhouseSale = () => {

    const [comProduct, setComProduct] = useState([])

    useEffect( () => {
        try {
          axios.get(`${api_url}/saleproduct/get`).then((res) => {
            console.log("=====all sale Product===", res.data)
            setComProduct(res.data.data)
          })
        } catch (error) {
          console.log(error)
        }
      },[])
    
    
    
      function separateArraysByProductId(arr) {
        const separatedArrays = [];
    
        // Create a map to store arrays of objects by productId
        const map = new Map();
    
        // Iterate over the array and group objects by productId
        for (const obj of arr) {
          const productId = obj.productId;
    
          if (map.has(productId)) {
            // If productId already exists in the map, push the object to the existing array
            const existingArray = map.get(productId);
            existingArray.push(obj);
          } else {
            // Otherwise, create a new array and add the object to the map
            map.set(productId, [obj]);
          }
        }
    
        // Iterate over the map and push arrays to the result array
        for (const objArray of map.values()) {
          separatedArrays.push(objArray);
        }
    
        return separatedArrays;
      }
    
      const sellerSale = separateArraysByProductId(comProduct);

      console.log(sellerSale,'aghfsahsa')

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/inhouse_sale.png"
                            alt=""
                        />
                        Inhouse sale
                    </h2>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <form >
                                    <input
                                        type="hidden"
                                        name="_token"
                                        defaultValue="cj2nMV9M6ekEkV26grL9W3t43zCNVxr8zOFR2LKG"
                                    />{" "}
                                    <div className="row gy-2 align-items-center">
                                        <div className="col-sm-9">
                                            <div className="d-flex align-items-center gap-10">
                                                <label
                                                    htmlFor="exampleInputEmail1"
                                                    className="title-color mb-0"
                                                >
                                                    Category
                                                </label>
                                                <select
                                                    className="js-select2-custom form-control select2-hidden-accessible"
                                                    name="category_id"
                                                    data-select2-id={1}
                                                    tabIndex={-1}
                                                    aria-hidden="true"
                                                >
                                                    <option value="all" data-select2-id={3}>
                                                        All
                                                    </option>
                                                    <option value={11} data-select2-id={4}>
                                                        Women's fashion
                                                    </option>
                                                    <option value={12} data-select2-id={5}>
                                                        Men's fashion
                                                    </option>
                                                    <option value={13} data-select2-id={6}>
                                                        Outdoor Fun &amp; Sports
                                                    </option>
                                                    <option value={14} data-select2-id={7}>
                                                        Toys , Kids &amp; Babies
                                                    </option>
                                                    <option value={15} data-select2-id={8}>
                                                        Jewelry &amp; Watches
                                                    </option>
                                                    <option value={16} data-select2-id={9}>
                                                        Beauty, Health &amp; Hair
                                                    </option>
                                                    <option value={24} data-select2-id={10}>
                                                        Home Improvement &amp; Tools
                                                    </option>
                                                    <option value={25} data-select2-id={11}>
                                                        Computer, Office &amp; Security
                                                    </option>
                                                    <option value={26} data-select2-id={12}>
                                                        Phones &amp; Telecom
                                                    </option>
                                                    <option value={27} data-select2-id={13}>
                                                        Bags &amp; Shoes
                                                    </option>
                                                    <option value={28} data-select2-id={14}>
                                                        Home, Pet &amp; Appliances
                                                    </option>
                                                    <option value={34} data-select2-id={15}>
                                                        eBook
                                                    </option>
                                                    <option value={39} data-select2-id={16}>
                                                        Applications
                                                    </option>
                                                </select>
                                                <span
                                                    className="select2 select2-container select2-container--default"
                                                    dir="ltr"
                                                    data-select2-id={2}
                                                    style={{ width: "100%" }}
                                                >
                                                    <span className="selection">
                                                        <span
                                                            className="select2-selection custom-select"
                                                            role="combobox"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            tabIndex={0}
                                                            aria-disabled="false"
                                                            aria-labelledby="select2-category_id-w4-container"
                                                        >
                                                            <span
                                                                className="select2-selection__rendered"
                                                                id="select2-category_id-w4-container"
                                                                role="textbox"
                                                                aria-readonly="true"
                                                                title="All"
                                                            >
                                                                <span>All</span>
                                                            </span>
                                                            <span
                                                                className="select2-selection__arrow"
                                                                role="presentation"
                                                            >
                                                                <b role="presentation" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <span className="dropdown-wrapper" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <button type="submit" className="btn btn-primary btn-block">
                                                Filter
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="table-responsive" style={{ textAlign: "left" }}>
                                <table 
                                id='allOrder'
                                className="table table-hover table-borderless table-thead-bordered table-align-middle card-table w-100">
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th>SL </th>
                                            <th>Product Name</th>
                                            <th className="text-center">Total Sale</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {sellerSale?.length>0?sellerSale && sellerSale?.map((i, index) => {
                                        if(i[0]?.product?.addedAs==='admin'){
                                            return (<>
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>{i[0]?.product?.name}</td>
                                            <td className="text-center">{i.length}</td>
                                        </tr>
                                        </>)
                                        }
                                        }):''}
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive mt-4">
                                <div className="px-4 d-flex justify-content-lg-end"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InhouseSale
