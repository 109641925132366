import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from "antd";
import { createBanner } from '../../store/bannerSlice';
import { createseller,getByIdseller,updateseller } from '../../store/sellerSlice';
import { useParams } from 'react-router-dom';

const UpdateSeller = () => {
    const {id}=useParams()
    const dispatch = useDispatch();
    const { data: seller, status } = useSelector((state) => state?.seller)
    useEffect(() => {
       
        dispatch(getByIdseller(id));
    }, [])

    // const handleBrand = (values) => {

    //     if ((document.getElementById(`name`).value.length === 0)) {
    //         message.error('Must enter name')
    //     }
    //     else {

    //         dispatch(createBanner({
    //             name: values.name.value,
    //             logo: 'logo',
    //         }));
    //     }

    // };




    const handleCategory = (values) => {

        console.log('click')



        if ((document.getElementById(`firstName`).value.length === 0)) {
            message.error('Must enter firstName')
        }
        else if ((document.getElementById(`lastName`).value.length === 0)) {
            message.error('Must enter lastName')
        }
        else if ((document.getElementById(`phone`).value.length === 0)) {
            message.error('Must enter phone')
        }
   
        else if ((document.getElementById(`email`).value.length === 0)) {
            message.error('Must enter email')
        }
        else if ((document.getElementById(`password`).value.length === 0)) {
            message.error('Must enter password')
        }
        else if ((document.getElementById(`shopName`).value.length === 0)) {
            message.error('Must enter shop name')
        }
        else if ((document.getElementById(`address`).value.length === 0)) {
            message.error('Must enter shop address')
        }
    
        else {

            console.log('Loading')
            const params = new FormData()
            params.append("firstName", values.firstName.value);
            params.append("lastName", values.lastName.value);
            params.append("email", values.email.value);
            params.append("phone", values.phone.value);
            params.append("password", values.password.value);
            params.append("image", values.image.files[0]);
            params.append("shopName", values.shopName.value);
            params.append("address", values.address.value);
            params.append("logo", values.logo.files[0]);
            params.append("banner", values.banner.files[0]);

            dispatch(updateseller(id,params));
        }

    };

    const [images, setImages] = useState([]);


    const imageHandler = (e) => {
        setImages(e.target.files);
    };

    console.log(images, '==================>upload images')


    const [images2, setImages2] = useState([]);


    const imageHandler2 = (e) => {
        setImages2(e.target.files);
    };

    console.log(images2, '==================>upload images')


    const [images3, setImages3] = useState([]);


    const imageHandler3 = (e) => {
        setImages3(e.target.files);
    };

    console.log(images3, '==================>upload images')



    return (
        <div className='toppp mt-5'>
                <div className="content container-fluid main-card ltr mt-5">
                    <div className="mb-4">
                        <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                            <img
                                src="https://6valley.6amtech.com/public/assets/back-end/img/add-new-seller.png"
                                className="mb-1"
                                alt=""
                            />
                            Update Seller
                        </h2>
                    </div>
                    <form
                        onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}
                    >
                        <div className="card">
                            <div className="card-body">
                                <h5 className="mb-0 text-capitalize d-flex align-items-center gap-2 border-bottom pb-3 mb-4 pl-4">
                                    <img
                                        src="https://6valley.6amtech.com/public/assets/back-end/img/seller-information.png"
                                        className="mb-1"
                                        alt=""
                                    />
                                    Seller information
                                </h5>
                                <div className="row align-items-center">
                                    <div className="col-lg-6 mb-4 mb-lg-0">
                                        <div className="form-group">
                                            <label
                                                htmlFor="exampleFirstName"
                                                className="title-color d-flex gap-1 align-items-center"
                                            >
                                                First name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-user"
                                                id="firstName"
                                                name="firstName"
                                             
                                                placeholder="Ex: Jhone"
                                                required=""
                                                defaultValue={seller?.firstName}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="exampleLastName"
                                                className="title-color d-flex gap-1 align-items-center"
                                            >
                                                Last name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-user"
                                                id="lastName"
                                                name="lastName"
                                                defaultValue={seller?.lastName}
                                                placeholder="Ex: Doe"
                                                required=""
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="exampleInputPhone"
                                                className="title-color d-flex gap-1 align-items-center"
                                            >
                                                Phone
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control form-control-user"
                                                id="phone"
                                                name="phone"
                                                defaultValue={seller?.phone}
                                                placeholder="Ex: +09587498"
                                                required=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                                                <div className="form-group">

                                                    {images.length > 0 ?
                                                        <center>
                                                            <div className='position-relative'>
                                                                <img src={URL.createObjectURL(images[0])} className='border rounded-3' />
                                                                <span className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white' style={{ left: '98%', lineHeight: '15px', fontSize: 'x-small', cursor: 'pointer' }} onClick={() => {
                                                                    setImages([])
                                                                }}>x</span>
                                                            </div>
                                                        </center> :
                                                        <center>
                                                            <img
                                                                className="upload-img-view"
                                                                id="viewer"
                                                                src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                                                alt="image"
                                                            />
                                                        </center>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="title-color mb-2 d-flex gap-1 align-items-center">
                                                Seller Image <span className="text-info">(Ratio 1:1)</span>
                                            </div>
                                            <div className="custom-file text-left">
                                                <input
                                                    type="file"
                                                    name="image"
                                                    id="image"
                                                    className="custom-file-input"
                                                    accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                                    onChange={(e) => imageHandler(e)}
                                                    defaultValue={seller?.image}
                                                />
                                                <label className="custom-file-label" htmlFor="customFileUpload">
                                                    Upload Image
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-body">
                                <input type="hidden" name="status" defaultValue="approved" />
                                <h5 className="mb-0 text-capitalize d-flex align-items-center gap-2 border-bottom pb-3 mb-4 pl-4">
                                    <img
                                        src="https://6valley.6amtech.com/public/assets/back-end/img/seller-information.png"
                                        className="mb-1"
                                        alt=""
                                    />
                                    Account information
                                </h5>
                                <div className="row">
                                    <div className="col-lg-4 form-group">
                                        <label
                                            htmlFor="exampleInputEmail"
                                            className="title-color d-flex gap-1 align-items-center"
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control form-control-user"
                                            id="email"
                                            name="email"
                                            defaultValue={seller?.email}
                                            placeholder="Ex: Jhone@company.com"
                                            required=""
                                        />
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label
                                            htmlFor="exampleInputPassword"
                                            className="title-color d-flex gap-1 align-items-center"
                                        >
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control form-control-user"
                                            minLength={8}
                                            id="password"
                                            name="password"
                                            placeholder="Ex: 8+ Character"
                                            required=""
                                            defaultValue={seller?.password}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <div className="card-body">
                                <h5 className="mb-0 text-capitalize d-flex align-items-center gap-2 border-bottom pb-3 mb-4 pl-4">
                                    <img
                                        src="https://6valley.6amtech.com/public/assets/back-end/img/seller-information.png"
                                        className="mb-1"
                                        alt=""
                                    />
                                    Shop information
                                </h5>
                                <div className="row">
                                    <div className="col-lg-6 form-group">
                                        <label
                                            htmlFor="shopName"
                                            className="title-color d-flex gap-1 align-items-center"
                                        >
                                            Shop name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-user"
                                            id="shopName"
                                            name="shopName"
                                            placeholder="Ex: Jhon"
                                            defaultValue={seller?.shopName}
                                            required=""
                                        />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label
                                            htmlFor="shop_address"
                                            className="title-color d-flex gap-1 align-items-center"
                                        >
                                            Shop address
                                        </label>
                                        <textarea
                                            name="address"
                                            className="form-control"
                                            id="address"
                                            rows={1}
                                            placeholder="Ex: Doe"
                                            defaultValue={seller?.address}
                                        />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                                            <div className="form-group">

                                                {images2.length > 0 ?
                                                    <center>
                                                        <div className='position-relative'>
                                                            <img src={URL.createObjectURL(images2[0])} className='border rounded-3' />
                                                            <span className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white' style={{ left: '98%', lineHeight: '15px', fontSize: 'x-small', cursor: 'pointer' }} onClick={() => {
                                                                setImages2([])
                                                            }}>x</span>
                                                        </div>
                                                    </center> :
                                                    <center>
                                                        <img
                                                            className="upload-img-view"
                                                            id="viewer2"
                                                            src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                                            alt="image2"
                                                        />
                                                    </center>}

                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="d-flex gap-1 align-items-center title-color mb-2">
                                                Shop logo
                                                <span className="text-info">(Ratio 1:1)</span>
                                            </div>
                                            <div className="custom-file">
                                                <input
                                                    type="file"
                                                    name="logo"
                                                    id="logo"
                                                    className="custom-file-input"
                                                    accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                                    onChange={(e) => imageHandler2(e)}
                                                />
                                                <label className="custom-file-label" htmlFor="LogoUpload">
                                                    Upload Logo
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                                            <div className="form-group">

                                                {images3.length > 0 ?
                                                    <center>
                                                        <div className='position-relative'>
                                                            <img src={URL.createObjectURL(images3[0])} className='border rounded-3' />
                                                            <span className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white' style={{ left: '98%', lineHeight: '15px', fontSize: 'x-small', cursor: 'pointer' }} onClick={() => {
                                                                setImages3([])
                                                            }}>x</span>
                                                        </div>
                                                    </center> :
                                                    <center>
                                                        <img
                                                            className="upload-img-view"
                                                            id="viewer3"
                                                            src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                                            alt="image3"
                                                        />
                                                    </center>}

                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="d-flex gap-1 align-items-center title-color mb-2">
                                                Shop banner
                                                <span className="text-info">(Ratio 3:1)</span>
                                            </div>
                                            <div className="custom-file">
                                                <input
                                                    type="file"
                                                    name="banner"
                                                    id="banner"
                                                    className="custom-file-input"
                                                    accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                                    onChange={(e) => imageHandler3(e)}
                                                />
                                                <label className="custom-file-label" htmlFor="BannerUpload">
                                                    Upload Banner
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end gap-10">
                                    <button
                                        type="reset"
                                        onclick="resetBtn()"
                                        className="btn btn-secondary"
                                    >
                                        Reset{" "}
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-user mx-3"
                                        id="apply"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            

        </div>
    )
}

export default UpdateSeller