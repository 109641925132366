import React,{useState,useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
// import Base64Image from "../../components/Base64Image";
import { fetchcategory} from "../../store/categorySlice";
import { fetchsubCategory } from "../../store/subCategorySlice";
import { fetchsubSubCategory } from "../../store/subSubCategorySlice";
import { fetchbrand } from "../../store/brandSlice";
import { createproduct } from "../../store/productSlice";
import Loader from "../Loader";

const AddProduct = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [productTypeValue, setProductTypeValue] = useState("")
  const [categoryCommission, setCategoryCommission] = useState("")
  const [unitPrice, setUnitPrice] = useState("")
  const [tax, setTax] = useState("")
  const [discount, setDiscount] = useState("")
  const [discountType, setDiscountType] = useState("")
  const [quantity, setQuantity] = useState("")
  const [shippingCost, setShippingCost] = useState("")

  const [code, setCode] = useState('');

  const generateRandomCode = () => {
    const randomCode = Math.floor(Math.random() * 100000).toString().padStart(5, '0');
    setCode(randomCode);
  };

  const { data: category, status } = useSelector((state) => state?.category);
  console.log("=========all categories=============", category);
  console.log("=========all categoriesssssssss=============", categoryCommission);

  const catCommision = category?.filter((cat) => cat?.id === parseInt(categoryCommission));
  console.log(catCommision[0]?.adminComission)
  let catCommisionCheck = ""
  if(catCommision[0]?.adminComission){
    catCommisionCheck = catCommision[0]?.adminComission
  }
  console.log(catCommisionCheck)
  // setCatCommisionCheck(catCommision[0]?.adminComission)
  // console.log(catCommisionCheck)

  // const selectElement = document.getElementById("category");
  // if (selectElement !== null) {
  //   selectElement.addEventListener("category", () => {
  //     const selectedOption = selectElement.value;

  //     fetch(`${ApiUrl}/category/getById?id=` + selectedOption)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         // Handle the API response
  //         console.log(data);

  //         setOptions(data);
  //         setAdminCommun(data?.adminCommission);
  //       })
  //       .catch((error) => {
  //         // Handle errors
  //         console.error(error);
  //       });

  //     // const categoryByid =   axios.get(`${ApiUrl}/category/getById?id=`+ selectedOption)
  //   });
  // }

const { data: subCategory, substatus } = useSelector((state) => state?.subCategory);
console.log(subCategory);

const { data: subSubCategory, subsubstatus } = useSelector((state) => state?.subSubCategory);
console.log(subSubCategory);

const { data: brand, brandsubstatus } = useSelector((state) => state?.brand);
console.log(brand);

useEffect(() => {
  dispatch(fetchcategory());
  dispatch(fetchsubCategory());
  dispatch(fetchsubSubCategory());
  dispatch(fetchbrand());
}, []);

  const handleSubmit = (values) => {
    if (values.name.value.length === 0) {
      message.error("please enter your product name");
    } else if (values.description.value.length === 0) {
      message.error("please enter your product description");
    } else if (values.productType.value.length === 0) {
      message.error("please enter your product type");
    } else if (values.category.value.length === 0) {
      message.error("please enter your product category");
    } else if (values.subCategory.value.length === 0) {
      message.error("please enter your product sub category");
    } else if (values.subSubCategory.value.length === 0) {
      message.error("please enter your product sub sub catogory");
    } else if (values.productCode.value.length === 0) {
      message.error("please enter your product code");
    } else if (values.brand.value.length === 0) {
      message.error("please enter your product brand");
    } else if (values.unit.value.length === 0) {
      message.error("please enter your product unit");
    } else if (values.color.value.length === 0) {
      message.error("please enter your product color");
    } else if (values.attributes.value.length === 0) {
      message.error("please enter your product attributes");
    } else if (values.unitPrice.value.length === 0) {
      message.error("please enter your product unit pricesssssssss");
    } else if (values.purchasePrice.value.length === 0) {
      message.error("please enter your product purchasePrice");
    } else if (values.taxPercent.value.length === 0) {
      message.error("please enter your product text percent");
    } else if (values.discount.value.length === 0) {
      message.error("please enter your product discount");
    } else if (values.discountType.value.length === 0) {
      message.error("please enter your product discount type");
    }else if (values.sellPrice.value.length === 0) {
      message.error("please enter Product Selling Price");
    } else if (values.tags.value.length === 0) {
      message.error("please enter your product tags");
    } else if (values.metaTitle.value.length === 0) {
      message.error("please enter your product meta title");
    } else if (values.metaDescription.value.length === 0) {
      message.error("please enter your product meta description");
    } else if (values.metaImage.value.length === 0) {
      message.error("please enter your meta image");
    } else if (values.youtubeLink.value.length === 0) {
      message.error("please enter your youtube link");
    } else if (values.productImage.value.length === 0) {
      message.error("please enter your product Image");
    } else if (values.thumbnail.value.length === 0) {
      message.error("please enter your thumbnail");
    }

    if(values.productType.value === "digital"){
      if (values.digitalProductType.value.length === 0) {
        message.error("please enter your digital product type");
      }
    }

    if(values.productType.value === "physical"){
      if (values.totalQuantity.value.length === 0) {
        message.error("please enter your product total quantity");
      } else if (values.minimumOrderQuantity.value.length === 0) {
        message.error("please enter your product minimum Order Quantity");
      } else if (values.shippingCost.value.length === 0) {
        message.error("please enter your product shipping cost");
      } else if (values.shippingCostMulti.value.length === 0) {
        message.error("please enter your product shipping cost multiple");
      }
    }
    setLoading(true)
    const param=new FormData()
    param.append('staffId',localStorage.getItem("staffId"));
    param.append('addedAs','admin');
    param.append('name',values.name.value);
    param.append('description',values.description.value);
    param.append('productType',values.productType.value);
    param.append('digitalProductType',"");
    param.append('categoryId',values.category.value);
    param.append('subCategoryId',values.subCategory.value);
    param.append('subSubCategoryId',values.subSubCategory.value);
    param.append('productCode',values.productCode.value);
    param.append('brandId',values.brand.value);
    param.append('unit',values.unit.value);
    param.append('color',values.color.value);
    param.append('attributes',values.attributes.value);
    param.append('sellPrice',values.sellPrice.value);
    param.append('unitPrice',values.unitPrice.value);
    param.append('purchasePrice',values.purchasePrice.value);
    param.append('tax', (parseInt(unitPrice) * parseInt(values.taxPercent.value))/100);
    param.append('discount', discountType==="percent" ? ((parseInt(unitPrice)*parseInt(values.discount.   value))/100): parseInt(values.discount.value));
    param.append('discountType',values.discountType.value)
    param.append('quantity',values.totalQuantity.value)
    param.append('minimumOrderQuantity',values.minimumOrderQuantity.value)
    param.append('shippingCost',values.shippingCost.value)
    param.append('shippingCostMulti',values.shippingCostMulti.value)
    param.append('tags',values.tags.value)
    param.append('metaTitle',values.metaTitle.value)
    param.append('metaDescription',values.metaDescription.value)
    param.append('metaImage',values.metaImage.files[0])
    param.append('youtubeLink',values.youtubeLink.value)
    param.append('productImage',values.productImage.files[0])
    param.append('thumbnail',values.thumbnail.files[0])
    param.append('status',true)


    setLoading(false)
    dispatch(
      createproduct(param)
    );
  };

    


  return (
    <div className="content container-fluid mt-5 toppp ">
       {loading?<Loader/>:null}
      <div className="d-flex flex-wrap gap-2 align-items-center mb-3">
        <h2 className="h1 mb-0">
          <img
            src="http://localhost/6valley/public/assets/back-end/img/all-orders.png"
            className="mb-1 mr-1"
            alt=""
          />
          Add new product
        </h2>
      </div>
      <div className="row">
        <div className="col-md-12">
          <form
            className="product-form"
            style={{ textAlign: "left" }}
            id="product_form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e.target);
            }}
          >
         
            <div className="card">
              <div className="px-4 pt-3"></div>
              <div className="card-body">
                <div className=" lang_form" id="en-form">
                  <div className="form-group">
                    <label className="title-color" htmlFor="">
                      Name (EN)
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="New Product"
                    />
                  </div>
                  
                </div>
                <div className=" lang_form">
                  <div className="form-group">
                    <label className="title-color" htmlFor="en_name">
                      Description
                    </label>
                    <textarea className="form-control" placeholder="Enter description" rows='30'  name="description"></textarea>
                    {/* <input
                      type="text"
                      required=""
                      name="description"
                      className="form-control"
                      placeholder="Description"
                    /> */}
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="card mt-2 rest-part">
              <div className="card-header">
                <h5 className="mb-0">General info</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 mb-3 ">
                      <label htmlFor="name" className="title-color">
                        Product type
                      </label>
                      <select
                        name="productType"
                        className="form-control"
                        required=""
                        onChange={(e)=>setProductTypeValue(e.target.value)}
                      >
                        <option value="" selected disabled>
                          ---Select Product Type---
                        </option>
                        <option value="physical">
                          Physical
                        </option>
                        <option value="digital">Digital</option>
                      </select>
                    </div>
                    {productTypeValue === "digital" && (
                      <div
                      className="col-md-4 mb-3 "
                      id="digital_product_type_show"
                     
                    >
                      <label
                        htmlFor="digital_product_type"
                        className="title-color"
                      >
                        Digital product type
                      </label>
                      <select
                        name="digitalProductType"
                        className="form-control"
                        required=""
                      >
                        <option value="" selected="" disabled="">
                          ---Select---
                        </option>
                        <option value="ready_after_sell">
                          Ready After Sell
                        </option>
                        <option value="ready_product">Ready Product</option>
                      </select>
                    </div>
                    )}
                    
                    {/* <div
                      className="col-md-4 mb-3 "
                      id="digital_file_ready_show"
                      style={{ display: "none" }}
                    >
                      <label
                        htmlFor="digital_file_ready"
                        className="title-color"
                      >
                        Ready product upload
                      </label>
                      <input
                        type="file"
                        name="digital_file_ready"
                        id="digital_file_ready"
                        className="form-control"
                      />
                      <div className="mt-1 text-info">
                        File type: jpg, jpeg, png, gif, zip, pdf
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-3 ">
                      <label htmlFor="name" className="title-color">
                        Category
                      </label>
                      <select
                        className="js-example-basic-multiple form-control"
                        name="category"
                        id="category"
                      >
                        <option  selected value={""}>---Select---</option>
                        { category && category?.map((item,index)=>{
                          return (
                            <>
                              <option value={item.id}>{item?.name}</option>
                            </>
                          )
                        })}  
                      </select>
                    </div>
                    <div className="col-md-4 mb-3 ">
                      <label htmlFor="name" className="title-color">
                        Sub category
                      </label>
                      <select
                        className="js-example-basic-multiple form-control"
                        name="subCategory"
                      >
                        <option  selected disabled>---Select---</option>
                        { subCategory && subCategory?.map((item,index)=>{
                          return (
                            <>
                              <option value={item.id}>{item?.name}</option>
                            </>
                          )
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3 ">
                      <label htmlFor="name" className="title-color">
                        Sub sub category
                      </label>
                      <select
                        className="js-example-basic-multiple form-control"
                        name="subSubCategory"
                      >
                        <option  selected disabled>---Select---</option>
                        { subSubCategory && subSubCategory?.map((item,index)=>{
                          return (
                            <>
                              <option value={item.id}>{item?.name}</option>
                            </>
                          )
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3 ">
                      <label htmlFor="code" className="title-color">
                        Product code sku
                        <span className="text-danger">*</span>
                        <a
                          className="style-one-pro"
                          onClick={generateRandomCode}
                        >
                          Generate Code
                        </a>
                      </label>
                      <input
                        type="number"
                        minLength={5}
                        name="productCode"
                        className="form-control"
                        defaultValue=""
                        placeholder="Code"
                        required=""
                        value={code}
                        readOnly
                      />
                    </div>
                    <div className="col-md-4 mb-3 ">
                      <label htmlFor="name" className="title-color">
                        Brand
                      </label>
                      <select
                        className="js-example-basic-multiple js-states js-example-responsive form-control select2-hidden-accessible"
                        name="brand"
                        required=""
                        aria-hidden="true"
                      >
                        <option
                          value=""
                          selected
                          disabled
                        >
                          ---Select---
                        </option>
                        { brand && brand?.map((item,index)=>{
                          return (
                            <>
                            <option value={item.id}>{item?.name}</option>
                            </>
                          )
                        })}
                      </select>
                      <span
                        className="select2 select2-container select2-container--default"
                        dir="ltr"
                        data-select2-id={2}
                        style={{ width: "306.984px" }}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--single"
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabIndex={0}
                            aria-disabled="false"
                            aria-labelledby="select2-brand_id-zo-container"
                          >
                            <span
                              className="select2-selection__arrow"
                              role="presentation"
                            >
                              <b role="presentation" />
                            </span>
                          </span>
                        </span>
                        <span className="dropdown-wrapper" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="col-md-4 mb-3 physical_product_show">
                      <label htmlFor="name" className="title-color">
                        Unit
                      </label>
                      <select
                        className="js-example-basic-multiple form-control"
                        name="unit"
                        id="unit"
                      >
                        <option value="kg">kg</option>
                        <option value="pc">pc</option>
                        <option value="gms">gms</option>
                        <option value="ltrs">ltrs</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-2 rest-part physical_product_show">
              <div className="card-header">
                <h5 className="mb-0">Variations</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <div className="row align-items-end">
                    <div className="col-md-6 mb-3">
                      <div className="d-flex gap-2 mb-1">
                        <label htmlFor="colors" className="title-color">
                          Colors (*Add Color with comma Seprated Value):
                        </label>
                      
                      </div>
                      <input
                        className="js-example-basic-multiple js-states js-example-responsive form-control select2-hidden-accessible"
                        name="color"
                        type="text"
                        id="color"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="attributes" className="title-color">
                        Attributes : (*Add Attribute with comma Seprated Value)
                      </label>
                      <input
                        className="js-example-basic-multiple js-states js-example-responsive form-control select2-hidden-accessible"
                        name="attributes"
                        type="text"
                        id="attributes"
                      />
                      <span
                        className="select2 select2-container select2-container--default"
                        dir="ltr"
                        data-select2-id={5}
                        style={{ width: "475.5px" }}
                      >
                        <span className="dropdown-wrapper" aria-hidden="true" />
                      </span>
                    </div>
                   
                    <div className="col-md-12 mb-2">
                      <div
                        className="customer_choice_options"
                        id="customer_choice_options"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-2 rest-part">
              <div className="card-header">
                <h5 className="mb-0">Product price &amp; stock</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <div className="row align-items-end">
                    <div className="col-md-4 mb-3">
                      <label className="title-color">Unit price</label>
                      <input
                        type="number"
                        min={0}
                        step="0.01"
                        placeholder="Unit price"
                        name="sellPrice"
                        id="sellPrice"
                        className="form-control"
                        required=""
                        onChange={(e)=>setUnitPrice(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="title-color">Purchase price</label>
                      <input
                        type="number"
                        min={0}
                        step="0.01"
                        placeholder="Purchase price"
                        name="purchasePrice"
                        id="purchasePrice"
                        defaultValue=""
                        className="form-control"
                        required=""
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="title-color">Tax</label>
                      <label className="badge badge-soft-info">
                        Percent ( % )
                      </label>
                      <input
                        type="number"
                        min={0}
                        onChange={(e)=>setTax(e.target.value)}
                        placeholder="Tax"
                        name="taxPercent"
                        className="form-control"
                      />
                     
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="title-color">Discount type</label>
                      <select
                        className="form-control js-select2-custom select2-hidden-accessible"
                        name="discountType"
                        id="discountType"
                        onChange={(e)=>setDiscountType(e.target.value)}
                      >
                        <option value="" selected disabled>---Select---</option>
                        <option value="flat" >
                          Flat
                        </option>
                        <option value="percent" >
                          Percent
                        </option>
                      </select>
                      <span
                        className="select2 select2-container select2-container--default"
                        dir="ltr"
                        data-select2-id={7}
                        style={{ width: "100%" }}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection custom-select"
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabIndex={0}
                            aria-disabled="false"
                            aria-labelledby="select2-discount_type-un-container"
                          >
                            <span
                              className="select2-selection__rendered"
                              id="select2-discount_type-un-container"
                              role="textbox"
                              aria-readonly="true"
                              title="Flat"
                            >
                           
                            </span>
                            <span
                              className="select2-selection__arrow"
                              role="presentation"
                            >
                              <b role="presentation" />
                            </span>
                          </span>
                        </span>
                        <span className="dropdown-wrapper" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="title-color">Discount</label>
                      <input
                        type="number"
                        min={0}
                        defaultValue={0}
                        step="0.01"
                        placeholder="Discount"
                        name="discount"
                        id="discount"
                        onChange={(e)=>setDiscount(e.target.value)}
                        className="form-control"
                        required=""
                      />
                    </div>
                    {productTypeValue === "physical" && (<>
                      <div
                      className="col-md-4 mb-3 physical_product_show"
                      id="quantity"
                    >
                      <label className="title-color">Total Quantity</label>
                      <input
                        type="number"
                        min={0}
                        placeholder="Enter Product Quantity"
                        name="totalQuantity"
                        id="totalQuantity"
                        className="form-control"
                        required
                        onChange={(e)=>setQuantity(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 mb-3" id="minimum_order_qty">
                    <label className="title-color">
                      Minimum order quantity
                    </label>
                    <input
                      type="number"
                      min={1}
                      defaultValue={1}
                      step={1}
                      placeholder="Minimum order quantity"
                      name="minimumOrderQuantity"
                      id="minimumOrderQuantity"
                      className="form-control"
                      required=""
                    />
                  </div>
                  <div
                      className="col-md-4 mb-3 physical_product_show"
                      id="shipping_cost"
                    >
                      <label className="title-color">Shipping Cost</label>
                      <input
                        type="number"
                        min={0}
                        placeholder="Product Shipping Cost"
                        name="shippingCost"
                       onChange={(e)=>setShippingCost(e.target.value)}
                        className="form-control"
                        required=""
                      />
                    </div>
                    <div
                      className="col-md-4 mb-3 physical_product_show"
                      id="shipping_cost_multy"
                    >
                      <div className="min-h-40">
                        <label className="title-color">
                          Shipping cost multiply with quantity
                        </label>

                        <input
                        type="number"
                        placeholder="Shipping cost"
                        name="shippingCostMulti"
                        id=""
                        value={quantity * shippingCost}
                        className="form-control"
                        disabled
                      />
                     
                      </div>
                    </div>
                  </>)}
  
                  <div
                      className="col-md-4 mb-3 physical_product_show"
                      id="shipping_cost_multy"
                    >
                      <div className="min-h-40">
                        <label className="title-color">
                          Product Price by adding Tax
                        </label>

                        <input
                          type="text"
                          placeholder="Product Price"
                          name="unitPrice"
                          id="adminCommision"
                          className="form-control"
                          value={parseInt(unitPrice)+(((parseInt(unitPrice) * parseInt(tax))/100)) - (discountType === "percent" ? (((parseInt(unitPrice) * parseInt(discount))/100)):parseInt(discount))}
                          disabled
                        />
                     
                      </div>
                    </div>
                    
                    
                    
                  </div>
                  <div className="sku_combination mb-3" id="sku_combination" />
                </div>
              </div>
            </div>
            <div className="card mt-2 mb-2 rest-part">
              <div className="card-header">
                <h5 className="mb-0">Seo section</h5>
              </div>
              <div className="card-body">
                <div className="row">
                <div className="col-md-12 mb-4">
                    <label className="title-color">Tags</label>
                    <input
                      type="text"
                      name="tags"
                     
                      placeholder=""
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-12 mb-4">
                    <label className="title-color">Meta title</label>
                    <input
                      type="text"
                      name="metaTitle"
                      id="metaTitle"
                      placeholder=""
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mb-0">
                      <label className="title-color">Meta image</label>
                    </div>
                    <input
                        type="file"
                        name="metaImage"
                        id="digital_file_ready"
                        className="form-control"
                      />
                  </div>
                  <div className="col-lg-8 mb-4">
                    <label className="title-color">Meta description</label>
                    <textarea
                      rows={10}
                      type="text"
                      name="metaDescription"
                      id="metaDescription"
                      className="form-control"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-2 rest-part">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <div className="d-flex flex-wrap gap-2 mb-2">
                      <label className="title-color mb-0">
                        Youtube video link
                      </label>
                      <span className="badge badge-soft-info">
                        {" "}
                        ( Optional please provide embed link not direct link. )
                      </span>
                    </div>
                    <input
                      type="text"
                      name="youtubeLink"
                      id="youtubeLink"
                      placeholder="EX : https://www.youtube.com/embed/5R06LRdUCSE"
                      className="form-control"
                      required=""
                    />
                  </div>
                  <div className="col-md-8 mb-3">
                    <div className="mb-2">
                      <label className="title-color mb-0">
                        Upload product images
                      </label>
                      <span className="text-info">* ( Ratio 1:1 )</span>
                    </div>
                    <div className="p-2 border border-dashed">
                      <div className="row" id="coba">
                        <div
                          className="col-md-6 col-lg-4 col-xl-3 spartan_item_wrapper"
                      
                          style={{ marginBottom: 20 }}
                        >
                          <div style={{ position: "relative" }}>
                            
                          
                          <input
                        type="file"
                        name="productImage"
                        id="digital_file_ready"
                        className="form-control"
                      />
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-2">
                      <label htmlFor="name" className="title-color mb-0">
                        Upload thumbnail
                      </label>
                      <span className="text-info">* ( Ratio 1:1 )</span>
                    </div>
                    <div>
                      <div className="row" id="thumbnail">
                        <div
                          className="col-md-6 spartan_item_wrapper"
                       
                          style={{ marginBottom: 20 }}
                        >
                          <div style={{ position: "relative" }}>
                            <div
                              className="spartan_item_loader"
                       
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: 220,
                                background: "rgba(255,255,255, 0.7)",
                                zIndex: 22,
                                textAlign: "center",
                                alignItems: "center",
                                margin: "auto",
                                justifyContent: "center",
                                flexDirection: "column",
                                display: "none",
                                fontSize: "1.7em",
                                color: "#CECECE",
                              }}
                            >
                              <i className="fas fa-sync fa-spin" />
                            </div>
                            <input
                        type="file"
                        name="thumbnail"
                        id="digital_file_ready"
                        className="form-control"
                      />
                        
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        onclick="check()"
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProduct
