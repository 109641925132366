import React,{useEffect} from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd';
import { deleteBanner } from '../../store/bannerSlice';
import { fetchBanner } from './../../store/bannerSlice';
import { useHistory,useNavigate } from 'react-router-dom';
const Banners = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { data: banner, status } = useSelector((state) => state.banner)

    useEffect(() => {
        dispatch(fetchBanner())
    }, [])

    console.log(banner,'assasasasa');

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteBanner(id))               
            }

        })
    }




    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/banner.png"
                            alt=""
                        />
                        Banner
                    </h2>
                </div>
                <div className="row" id="banner-table">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-lg-6 mb-2 mb-md-0">
                                        <h5 className="mb-0 text-capitalize d-flex gap-2">
                                            Banner table
                                            <span className="badge badge-soft-dark radius-50 fz-12">9</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table
                                    id="allOrder"
                                    style={{ textAlign: "left" }}
                                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                >
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th className="pl-xl-5">SL</th>
                                            <th>Image</th>
                                            <th>Banner type</th>
                                            {/* <th>Published</th> */}
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {banner.length>0?banner && banner?.map((i, index) => {
                                            return (<>
                                                <tr id="data-18">
                                                    <td className="pl-xl-5">{index + 1}</td>
                                                    <td>
                                                        <img
                                                            className="ratio-4:1"
                                                            width={80}
                                                            src={i?.image}
                                                        />
                                                    </td>
                                                    <td>{i.bannerType}</td>
                                                    {/* <td>
                                                        <label className="switcher">
                                                            <input
                                                                type="checkbox"
                                                                className="switcher_input status"
                                                                id={18}
                                                                defaultChecked=""
                                                            />
                                                            <span className="switcher_control" />
                                                        </label>
                                                    </td> */}
                                                    <td>
                                                        <div className="d-flex justify-content-center gap-2">
                                                        <button
                                                               className=" btn btn-outline-success square-btn btn-sm mr-1"
                                                      onClick={()=> navigate('/UpdateBanner/'+i?.id)}
                                                                                  >
                                                                 <i className="bi bi-pencil" />
                                                           </button>
                                                            <button
                                                                onClick={()=>onDelete(i.id)}
                                                                className=" btn btn-outline-danger square-btn btn-sm mr-1"
                                                                title="Delete"
                                                            >
                                                                <i className="bi bi-trash" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>)
                                        }):''}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default Banners
