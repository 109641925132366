import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const productSlice=createSlice({
    name: "product",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setproduct(state,action){
            state.data=action.payload
        },
        getproduct(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setproduct,setStatus,getproduct}=productSlice.actions
export default productSlice.reducer




// thunk

export function fetchproduct(){
    return async function fetchproductThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/product/get`).then(
                (resp) => {
                    console.log(resp.data)
                    dispatch(setproduct(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createproduct(param){
    return async function createproductThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/product/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("Product Created")
                    const base_url = window.location.origin;
                    window.location.href=base_url+"/#/products";
                    
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateproduct(id,param){
    return async function updateproductThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/product/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("product Updated")
                    // const base_url = window.location.origin;
                    // window.location.href=base_url+"/#/products";
                    // console.log("===================================updateeeeeeeeeee")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteproduct(id){
    return async function deleteproductThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/product/delete/${id}`).then(res=>{
                console.log(res.data)
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdproduct(id){
    return async function getByIdproductThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/product/get/${id}`).then(
                (resp) => {
                    dispatch(getproduct(resp.data.data))
                    console.log(resp.data,"yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy getbyid Api")
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
