import React, { useEffect, useState } from "react";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { deleteallOrder, fetchallOrder, updateallOrder } from "../../store/allOrderSlice";
import axios from "axios";
import { api_url } from "../../ApiUrl";

const AllOrder = () => {

  const [ourId, setOurId] = useState('')
  const [comProduct, setComProduct] = useState([])
  const [lastSaleId, setlastSaleId] = useState()

  const dispatch = useDispatch();
  const { data: allOrder, status } = useSelector((state) => state?.allOrder);

  useEffect (() => {
    try {
      axios.get(`${api_url}/saleproduct/get`).then((res) => {
        console.log("=====all sale Product===", res.data)
        setComProduct(res.data.data)
      })
    } catch (error) {
      console.log(error)
    }
  },[])



  

  useEffect(() => {
    dispatch(fetchallOrder());
    // handleSaleProduct()
  }, []);


  function separateArraysByProductId(arr) {
    const separatedArrays = [];

    // Create a map to store arrays of objects by productId
    const map = new Map();

    // Iterate over the array and group objects by productId
    for (const obj of arr) {
      const saleId = obj.saleId;

      if (map.has(saleId)) {
        // If productId already exists in the map, push the object to the existing array
        const existingArray = map.get(saleId);
        existingArray.push(obj);
      } else {
        // Otherwise, create a new array and add the object to the map
        map.set(saleId, [obj]);
      }
    }

    // Iterate over the map and push arrays to the result array
    for (const objArray of map.values()) {
      separatedArrays.push(objArray);
    }

    return separatedArrays;
  }

  const combinedProduct = separateArraysByProductId(comProduct);


  console.log("=========combine orders============",combinedProduct)



  $(document).ready(function () {
    setTimeout(function () {
      $("#allOrder").DataTable({
        pagingType: "full_numbers",
        pageLength: 25,
        processing: true,
        bDestroy: true,
        // dom: 'Bfrtip',
        // buttons: ['copy', 'csv', 'print']
      });
    }, 1000);
  });

  const onDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      onOk: () => {

        dispatch(deleteallOrder(id));

      },
    });
  };






  const handle = () => {

    dispatch(updateallOrder(ourId, {
      state: document.getElementById(`stati`).value,

    }));



  };



  const approveSeller = (id, status) => {
    Modal.confirm({
      title: 'Are you sure you want to approve?',
      onOk: () => {

        const param = new FormData();
        param.append('status', status);

        dispatch(updateallOrder(id, param))
      }

    })
  }




  return (
    <div className="mt-5 toppp" style={{ marginTop: "84px !important" }}>
      <div className="content container-fluid mt-5">
        <>
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">
                    Update Order Status
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <select className="form-control" id='stati'>
                    <option >Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="confirmed">confirmed</option>
                    <option value="process">processing</option>
                    <option value="outofdelivery">outofdelivery</option>
                    <option value="delivered">delivered</option>
                    <option value="return">return</option>
                    <option value="failed">failed</option>
                    <option value="cancel">cancel</option>
                  </select>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => handle()}
                  >
                    Update
                  </button>

                </div>
              </div>
            </div>
          </div>
        </>

        <div>
          <div className="d-flex flex-wrap gap-2 align-items-center mb-3">
            <h2 className="h1 mb-0">
              <img
                src="https://6valley.6amtech.com/public/assets/back-end/img/all-orders.png"
                className="mb-1 mr-1"
                alt=""
              />
              <span className="page-header-title">All</span> Orders
            </h2>
            <span className="badge badge-soft-dark radius-50 fz-14">184</span>
          </div>
          <div className="card">
            <div className="card">
              <div className="card-body">
                <div className="row gy-3 gx-2">
                  <div className="col-12 pb-0">
                    <h4>Select Date Range</h4>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <select name="filter" className="form-control">
                      <option value="all">All</option>
                      <option value="admin">In House</option>
                      <option value="seller">Seller</option>
                      <option value="POS">POS</option>
                    </select>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-floating">
                      <input
                        type="date"
                        name="from"
                        defaultValue=""
                        id="from_date"
                        className="form-control"
                      />
                      <label>Start Date</label>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-floating">
                      <input
                        type="date"
                        defaultValue=""
                        name="to"
                        id="to_date"
                        className="form-control"
                      />
                      <label>End Date</label>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      onclick="formUrlChange(this)"
                      data-action="https://6valley.6amtech.com/admin/orders/list/all"
                    >
                      Show Data
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive datatable-custom">
                <table
                  id="allOrder"
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                  style={{ textAlign: "left" }}
                >
                  <thead className="thead-light thead-50 text-capitalize">
                    <tr>
                      <th>Order ID</th>
                      <th>Order Date</th>
                      <th>Customer Info</th>
                      <th>Store</th>
                      <th className="text-right">Total Amount</th>
                      <th className="text-center">Order Status </th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {combinedProduct &&
                      combinedProduct?.map((i, index) => {
                        return (
                          <>
                            <tr className="status-pending class-all">
                              <td>
                                <a className="title-color">{index + 1}</a>
                              </td>
                              <td>
                                <div>{i[0]?.sale.createdAt}</div>
                              </td>
                              <td>
                                <a className="text-body text-capitalize">
                                  <strong className="title-name">
                                    {i[0].usersSP.firstName}
                                  </strong>
                                </a>
                                {/* <a className="d-block title-color" href="tel:545452">
                              545452
                            </a> */}
                              </td>
                              <td>
                                <span className="store-name font-weight-medium">
                                  {i[0]?.product!==null? i[0]?.product?.addedAs!=="admin"? i[0]?.product?.profile?.shopName:'Admin Shop':'Admin Shop'}
                                </span>
                              </td>
                              <td className="text-right">
                                <div>{i[0]?.sale?.totalBill}</div>
                              </td>
                              <td className="text-center text-capitalize">
                                <span className={`badge text-bg-${i[0]?.sale?.state === 'confirmed' ? 'success' : i[0]?.sale?.state === 'pending' ? 'warning' : i[0]?.sale?.state === 'failed' ? 'danger' : i[0]?.sale?.state === 'cancel' ? 'secondary' : i[0]?.sale?.state === 'return' ? 'primary' : i[0]?.sale?.state === 'delivered' ? 'info' : i[0]?.sale?.state === 'outofdelivery' ? 'success' : i[0]?.sale?.state === 'process' ? 'primary' : null} fz-12`}>
                                  {i[0]?.sale?.state}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex justify-content-center gap-2">
                                  <button
                                    className="btn btn-outline-primary square-btn btn-sm mr-1"
                                    title="View" data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                    onClick={() => { setOurId(i[0]?.sale?.id) }}
                                  >
                                    <i className="bi bi-exclamation-circle " />
                                  </button>
                                  {/* <button
                                    onClick={(_id) => onDelete(i[0]?.id)}
                                    className=" btn btn-outline-danger square-btn btn-sm mr-1"
                                    title="delete"
                                  >
                                    <i className="bi bi-trash " />
                                  </button> */}
                                </div>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllOrder;