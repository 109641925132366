import React, { useEffect, useState } from 'react'
import 'jquery/dist/jquery.min.js';
import { useHistory,useNavigate } from 'react-router-dom';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import UpdateSubCate from '../UpdateCategory/UpdateSubCate';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd';
import { createsubCategory, deletesubCategory, fetchsubCategory } from '../../store/subCategorySlice';
import { fetchcategory } from '../../store/categorySlice';
import Loader from '../Loader';


const SubCategory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { data: subCategory, statusSub } = useSelector((state) => state?.subCategory)
    const { data: category, statusC } = useSelector((state) => state?.category)

    useEffect(() => {
        dispatch(fetchcategory())
        dispatch(fetchsubCategory())
    }, [])

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const [loading, setLoading] = useState(false);

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deletesubCategory(id))
            }

        })
    }

    const handleCategory = (values) => {

        if ((document.getElementById(`name`).value.length === 0)) {
            message.error('Must enter name')
        }
        else if ((document.getElementById(`priority`).value.length === 0)) {
            message.error('Must enter priority')
        }
        else if ((document.getElementById(`categoryId`).value.length === 0)) {
            message.error('Must select category')
        }
        else {
setLoading(true)
            dispatch(createsubCategory({
                name: values.name.value,
                priority: values.priority.value,
                categoryId: values.categoryId.value,
            }));
        }

    };


    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>{loading?<Loader/>:null}
            <div className="content container-fluid mt-5" >
                <div>
                    <h2 className="h1 mb-0 d-flex gap-10">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png"
                            alt=""
                        />
                        Sub Category Setup
                    </h2>

                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group  lang_form" id="en-form">
                                        <label className="title-color" htmlFor="exampleFormControlInput1">
                                            Sub category Name<span className="text-danger">*</span>
                                            (EN)
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="form-control"
                                            placeholder="New Sub Category"
                                            required=""
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="title-color" htmlFor="exampleFormControlSelect1">
                                            Main Category
                                            <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            name="categoryId"
                                            id="categoryId"
                                            className="form-control"
                                            required=""
                                        >
                                            {category?.length>0?category && category?.map((i, index) => {
                                                return (<>
                                                    <option value={i.id}>{i.name}</option>
                                                </>)
                                            }):""}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="title-color" htmlFor="priority">
                                            Priority
                                            <span>
                                                <i
                                                    className="tio-info-outined"
                                                    title="The lowest number will get the highest priority"
                                                />
                                            </span>
                                        </label>
                                        <select className="form-control" name="priority" id="priority" required="">
                                        <option disabled>
                                            Set Priority
                                        </option>
                                        <option value='0'>0</option>
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                        <option value='3'>3</option>
                                        <option value='4'>4</option>
                                        <option value='5'>5</option>
                                        <option value='6'>6</option>
                                        <option value='7'>7</option>
                                        <option value='8'>8</option>
                                        <option value='9'>9</option>
                                        <option value='10'>10</option>
                                    </select>
                                    </div>
                                </div>
                            </div>


                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>

                        </div>
                    </form>
                    <div className="row mt-3" id="cate-table">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="text-capitalize d-flex gap-1">
                                                Sub Category list
                                                <span className="badge badge-soft-dark radius-50 fz-12">13</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id='allOrder'
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                                <th>Priority</th>
                                      
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subCategory?.length>0?subCategory && subCategory?.map((i, index) => {
                                                return (<>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{i?.name}</td>
                                                        <td>{i?.priority}</td>
                                                     
                                                        <td>
                                                            <div className="d-flex justify-content-center gap-2">
                                                            <button
                                                               className=" btn btn-outline-success square-btn btn-sm mr-1"
                                                      onClick={()=> navigate('/UpdateSubCate/'+i?.id)}
                                                                                  >
                                                                 <i className="bi bi-pencil" />
                                                           </button>

                                                           <button
                                                                    onClick={() => onDelete(i.id)}
                                                                    className=" btn btn-outline-danger square-btn btn-sm mr-1"
                                                                    title="Delete" >
                                                                    <i className="bi bi-trash" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>)
                                            }):''}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default SubCategory
