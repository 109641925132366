import { Link, NavLink } from "react-router-dom";
function Sidebar({ width, display }) {

    const NavLinkStyle = ({ isActive }) => {
        return {
            backgroundColor: isActive ? 'white' : '',
            borderLeft: isActive ? '5px solid #0c63e4' : 'none'
        }
    }
    return (
        <aside className="sidebar-wrapper" data-simplebar="true" style={{ width: width }}>
            <div className="sidebar-header" >
                <div>
                    <img
                        src={require("../download.png")}
                        className="logo-icon"
                        alt="logo icon"
                    />
                </div>
                <div>
                    <h4 className="logo-text">Ashh Karo</h4>
                </div>
                {/* <div className="toggle-icon ms-auto">
                    {" "}
                    <i className="bi bi-list" />
                </div> */}
            </div>
            <ul className="metismenu">
                <li>
                    <NavLink style={NavLinkStyle}
                        to="/dashboard"
                        className="accordion-button collapsed bg-light"
                    >
                        <div className="parent-icon">
                            <i className="bi bi-house-fill" />
                        </div>
                        <div className="menu-title ">Dashboard</div>
                    </NavLink>

                </li>
                <li>
                    <NavLink style={NavLinkStyle} to="/adminWallet" className="">
                        <div className="parent-icon">
                            <i className="bi bi-lightning-charge" />
                        </div>
                        <div className="menu-title">Admin Wallet</div>
                    </NavLink>
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="javascript:"
                        className="accordion-button collapsed bg-light"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                    >
                        <div className="parent-icon">
                            <i className="bi bi-border-width" />
                        </div>
                        <div className="menu-title">Orders</div>
                    </NavLink>
                    <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/allOrder'>
                                            {" "}
                                            <i className="bi bi-check-all px-3" /> &nbsp; All
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/pendingOrder'>
                                            {" "}
                                            <i className="bi bi-arrow-clockwise px-3" /> &nbsp; Pending
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/confirmedOrder'>
                                            {" "}
                                            <i className="bi bi-check-circle-fill px-3" /> &nbsp; Confirmed
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/processOrder'>
                                            {" "}
                                            <i className="bi bi-kanban-fill px-3" /> &nbsp; Processing{" "}
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/outDeliveryOrder'>
                                            {" "}
                                            <i className="bi bi-truck px-3" /> &nbsp; Out of delivery{" "}
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/deliveredOrder'>
                                            {" "}
                                            <i className="bi bi-truck px-3" /> &nbsp; Delivered{" "}
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/returnOrder'>
                                            {" "}
                                            <i className="bi bi-arrow-return-left px-3" /> &nbsp; Return{" "}
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/failedOrder'>
                                            {" "}
                                            <i className="bi bi-droplet-fill px-3" /> &nbsp; Failed
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/cancelOrder'>
                                            {" "}
                                            <i className="bi bi-x-diamond-fill px-3" /> &nbsp; Cancel
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="javascript:"
                        className="accordion-button collapsed bg-light"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                    >
                        <div className="parent-icon">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAJ5JREFUSEu9ldENwjAMBS8TdARgk44Ck8EojAIbsEGRJSwlESgWzmt/09zZL4lcEH9FzGdXwQpcgWOyqwdwAe7GqTuwhUMS7tuNdeoF22c1G1vDqWG7C1wYTc2LDXcgF0Qr7/8LdyAXyCOSC+QRyQW/Ihq99PAtkgvkEU0XvIDlX2q37+lzpT4wGzi3CTPB4OdvA2dS8S1mdOXSUrngDbnQLBkqK9hLAAAAAElFTkSuQmCC" />
                        </div>
                        <div className="menu-title">Brands</div>
                    </NavLink>
                    <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/addBrand'>
                                            {" "}
                                            <i className="bi bi-folder-plus px-3" /> &nbsp; Add new
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/brand'>
                                            {" "}
                                            <i className="bi bi-view-list px-3" /> &nbsp; List
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="javascript:"
                        className="accordion-button collapsed bg-light"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                    >
                        <div className="parent-icon">
                            <img
                                src={require("../package.7c5f68386e7fa3cdb6e4.png")}
                                width="20px"
                                height="20px"
                                alt=""
                            />
                        </div>
                        <div className="menu-title">In House products</div>
                    </NavLink>
                    <div
                        id="collapseTen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTen"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/products'>
                                            <i className="bi bi-bag-plus-fill px-3" /> &nbsp; Products
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/addProducts'>
                                            <i className="bi bi-bag-plus-fill px-3" /> &nbsp; Add New Product
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/stockProduct'>
                                            {" "}
                                            <i className="bi bi-bar-chart-line-fill px-3" /> &nbsp; Stock limit
                                            products
                                        </NavLink>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="javascript:"
                        className="accordion-button collapsed bg-light"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse11"
                        aria-expanded="false"
                        aria-controls="collapse11"
                    >
                        <div className="parent-icon">
                            <img
                                src={require("../cosmetics.60f65d7c0c6cc07a0cbd.png")}
                                width="20px"
                                height="20px"
                                alt=""
                            />
                        </div>
                        <div className="menu-title">Sellers products</div>
                    </NavLink>
                    <div
                        id="collapse11"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading11"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/newSellerProduct'>
                                            {" "}
                                            <i className="bi bi-bag-plus-fill px-3" />&nbsp; New products
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/approvedSellerProduct'>
                                            {" "}
                                            <i className="bi bi-check2-circle px-3" />&nbsp; Approved products
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/deniedSellerProduct'>
                                            {" "}
                                            <i className="bi bi-x-circle-fill px-3" />&nbsp; Denied products
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="javascript:"
                        className="accordion-button collapsed bg-light"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse12"
                        aria-expanded="false"
                        aria-controls="collapse12"
                    >
                        <div className="parent-icon">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAJ5JREFUSEu9ldENwjAMBS8TdARgk44Ck8EojAIbsEGRJSwlESgWzmt/09zZL4lcEH9FzGdXwQpcgWOyqwdwAe7GqTuwhUMS7tuNdeoF22c1G1vDqWG7C1wYTc2LDXcgF0Qr7/8LdyAXyCOSC+QRyQW/Ihq99PAtkgvkEU0XvIDlX2q37+lzpT4wGzi3CTPB4OdvA2dS8S1mdOXSUrngDbnQLBkqK9hLAAAAAElFTkSuQmCC" />
                        </div>
                        <div className="menu-title">Website banners</div>
                    </NavLink>
                    <div
                        id="collapse12"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading12"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/banners'>
                                            {" "}
                                            <i className="bi bi-palette-fill px-3" />&nbsp; Banners
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/addBanners'>
                                            {" "}
                                            <i className="bi bi-palette-fill px-3" />&nbsp; Add Banners
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="javascript:"
                        className="accordion-button collapsed bg-light"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse13"
                        aria-expanded="false"
                        aria-controls="collapse13"
                    >
                        <div className="parent-icon">
                            <i className="bi bi-grid-fill" />
                        </div>
                        <div className="menu-title">Category</div>
                    </NavLink>
                    <div
                        id="collapse13"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading13"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu mm-active">
                                <ul className="p-0 mm-show">
                                    <li className="mm-active">
                                        <NavLink style={NavLinkStyle} to="/category">
                                            {" "}
                                            <i className="bi bi-grid-fill px-3" />&nbsp; Categories
                                        </NavLink>
                                    </li>
                                    <li className="mm-active">
                                        <NavLink style={NavLinkStyle} to="/subCategory">
                                            {" "}
                                            <i className="bi bi-grid-fill px-3" /> &nbsp; Sub category
                                        </NavLink>
                                    </li>
                                    <li className="mm-active">
                                        <NavLink style={NavLinkStyle} to="/subSubCategory">
                                            {" "}
                                            <i className="bi bi-grid-fill px-3" /> &nbsp; Sub sub category
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        className="accordion-button collapsed bg-light"
                        to="javascript:"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                    >
                        <div className="parent-icon">
                            <i className="bi bi-award-fill" />
                        </div>
                        <div className="menu-title">Coupons</div>
                    </NavLink>
                    <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/flashDeal'>
                                            {" "}
                                            <i className="bi bi-flag-fill px-3" /> &nbsp;  Flash deals
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/dealDay'>
                                            {" "}
                                            <i className="bi bi-calendar-day-fill px-3" /> &nbsp;  Deal of the day
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to='/featuredDeal'>
                                            {" "}
                                            <i className="bi bi-award-fill px-3" /> &nbsp;  Featured deal
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="javascript:"
                        className="accordion-button collapsed bg-light"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                    >
                        <div className="parent-icon">
                            <i className="bi bi-arrow-repeat" />
                        </div>
                        <div className="menu-title">All Sellers</div>
                    </NavLink>
                    <div
                        id="collapseNine"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink to='/sellerList' style={NavLinkStyle}>
                                            {" "}
                                            <i className="bi bi-house-gear-fill px-3" /> &nbsp;  Seller List
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/addSeller'>
                                            {" "}
                                            <i className="bi bi-arrow-repeat px-3" /> &nbsp; Add Seller
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink style={NavLinkStyle}
                        to="javascript:"
                        className="accordion-button collapsed bg-light"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwelle"
                        aria-expanded="false"
                        aria-controls="collapseTwelle"
                    >
                        <div className="parent-icon">
                            <i className="bi bi-arrow-repeat" />
                        </div>
                        <div className="menu-title">Sales Report</div>
                    </NavLink>
                    <div
                        id="collapseTwelle"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwelle"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink to='/sellerSale' style={NavLinkStyle}>
                                            {" "}
                                            <i className="bi bi-house-gear-fill px-3" /> &nbsp;  Seller Sales
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink style={NavLinkStyle} to='/inhouseSale'>
                                            {" "}
                                            <i className="bi bi-arrow-repeat px-3" /> &nbsp; Inhouse Sales
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <NavLink style={NavLinkStyle} to="/customer" className="">
                        <div className="parent-icon">
                            <i className="bi bi-card-list" />
                        </div>
                        <div className="menu-title">Customer list</div>
                    </NavLink>
                </li>
                <li>
                    <NavLink style={NavLinkStyle} to="/sms" className="">
                        <div className="parent-icon">
                            <i className="bi bi-credit-card-fill" />
                        </div>
                        <div className="menu-title">SMS GATEWAY</div>
                    </NavLink>
                </li>

                <li>
                    <Link
                        href="javascript:"
                        className="accordion-button collapsed bg-light"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse14"
                        aria-expanded="false"
                        aria-controls="collapse14"
                    >
                        <div className="parent-icon">
                            <div className="parent-icon">
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAJ5JREFUSEu9ldENwjAMBS8TdARgk44Ck8EojAIbsEGRJSwlESgWzmt/09zZL4lcEH9FzGdXwQpcgWOyqwdwAe7GqTuwhUMS7tuNdeoF22c1G1vDqWG7C1wYTc2LDXcgF0Qr7/8LdyAXyCOSC+QRyQW/Ihq99PAtkgvkEU0XvIDlX2q37+lzpT4wGzi3CTPB4OdvA2dS8S1mdOXSUrngDbnQLBkqK9hLAAAAAElFTkSuQmCC" />
                            </div>
                        </div>
                        <div className="menu-title">Withdrawals</div>
                    </Link>
                    <div
                        id="collapse14"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading14"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body p-0">
                            <div className="sub-menu">
                                <ul className="p-0">
                                    <li>
                                        <NavLink style={NavLinkStyle} to="/withdrawsApproved">
                                            {" "}
                                            <i className="bi bi-bag-plus px-3" /> &nbsp; Approved
                                        </NavLink>
                                        <NavLink style={NavLinkStyle} to="/withdrawsPending">
                                            {" "}
                                            <i className="bi bi-list-task px-3" /> &nbsp; Pending
                                        </NavLink>
                                        {/* <NavLink style={NavLinkStyle} to="/withdrawsDenied">
                                            {" "}
                                            <i className="bi bi-bank px-3" /> &nbsp; Denied
                                        </NavLink> */}

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                {/* <li>
                    <NavLink style={NavLinkStyle} to="/shippingCharges" className="">
                        <div className="parent-icon">
                            <i className="bi bi-lightning-charge" />
                        </div>
                        <div className="menu-title">Shipping charges</div>
                    </NavLink>
                </li> */}
                <li>
                    <NavLink style={NavLinkStyle} to="/" className="">
                        <div className="parent-icon">
                            <i className="bi bi-box-arrow-left" />
                        </div>
                        <div className="menu-title">Logout</div>
                    </NavLink>
                </li>



            </ul>
        </aside>

    );
}

export default Sidebar