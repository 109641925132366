import React, { useEffect, useState } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import { Navigate, useHistory,useNavigate } from 'react-router-dom';
// import Modals from '../Modals/Modals';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import {Modal, message } from 'antd';
import { createcategory, deletecategory, fetchcategory,updatecategory ,getByIdcategory} from '../../store/categorySlice';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';



const UpdateCategorey = () => {
    // const [singleStock, setSingleStock] = useState({});

    const {id}=useParams()

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const { data: category, status } = useSelector((state) => state?.category)


    
    useEffect(() => {
        dispatch(getByIdcategory(id));
    }, []);
    
    console.log(category,"=============================samar")




    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });




    const [images, setImages] = useState([]);


    const imageHandler = (e) => {
        setImages(e.target.files);
    };

    console.log(images, '==================>upload images')


   





 

    const handleCategory = (values) => {

        if ((document.getElementById(`name`).value.length === 0)) {
            message.error('Must enter name')
        }
     
        else {
            setLoading(true)
            const param = new FormData()
            param.append('id' ,id)
            param.append('name', values.name.value)
            param.append('adminComission', values.adminComission.value)
            param.append('image', images[0])
            dispatch(updatecategory(id,param));
        }
    };


    





    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            <div className="content container-fluid mt-5" >
                <div>
                    <h2 className="h1 mb-0 d-flex gap-10">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png"
                            alt=""
                        />
                        Category Setup
                    </h2>
                    {loading?<Loader/>:null}
                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div>
                                        <div className="form-group  lang_form" id="en-form">
                                            <label className="title-color">
                                                Category Name<span className="text-danger">*</span> (EN)
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="form-control"
                                                placeholder="New Category"
                                                required=""
                                                defaultValue={category?.name}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-group  lang_form" id="en-form">
                                            <label className="title-color">
                                                Admin Commission<span className="text-danger">*</span> (EN)
                                            </label>
                                            <input
                                                type="text"
                                                name="adminComission"
                                                id="adminComission"
                                                className="form-control"
                                                placeholder="New Category"
                                                required=""
                                                defaultValue={category?.adminComission}
                                            />
                                        </div>
                                    </div>
                                 
                                    <div className="from_part_2">
                                        <label className="title-color">Category Logo</label>
                                        <span className="text-info">
                                            <span className="text-danger">*</span> ( Ratio 1:1 )
                                        </span>
                                        <div className="custom-file text-left">
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                className="custom-file-input"
                                                accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                                required=""
                                                onChange={(e)=>imageHandler(e)}
                                                defaultValue={category?.image}
                                            />
                                            <label className="custom-file-label" htmlFor="customFileEg1">
                                                Choose File
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                                    <div className="form-group">

                                    {images.length>0 ?
                                    <center>
                                            <div className='position-relative'>
                                                <img src={URL.createObjectURL(images[0])}  className='border rounded-3' />
                                                <span className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white' style={{ left: '98%', lineHeight: '15px', fontSize: 'x-small', cursor: 'pointer' }} onClick={() => {
                                                    setImages([])
                                                }}>x</span>
                                            </div>
                                            </center>:
                                    <center>
                                            <img
                                                className="upload-img-view"
                                                id="viewer"
                                                src={category?.image}
                                                alt="image"
                                            />
                                        </center>}
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>

                        </div>
                    </form>

                 

                </div>
            </div>


        </div>
    )
}

export default UpdateCategorey