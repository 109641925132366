import React, { useEffect, useState } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd';
import {updatebrand ,getByIdbrand,fetchbrand } from '../../store/brandSlice';

import { useParams } from 'react-router-dom';
import Loader from '../Loader';

const UpdateBrand = () => {
    const [allImg,setAllImg]=useState('')
    const {id}=useParams()
    const dispatch = useDispatch()
    const { data: brand, status } = useSelector((state) => state?.brand)
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        dispatch(fetchbrand())
        dispatch(getByIdbrand(id));
    }, [])
// console.log(statusSub,"00000000000000000000000000000000000000")
    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    




 


    const handleBrand = (values) => {
        
        if ((document.getElementById(`name`).value.length === 0)) {
            message.error('Must enter name')
        }
        else {
            setLoading(true)
            const param=new FormData()
            param.append('name',values.name.value)
            param.append('image',values.image.files[0])

            dispatch(updatebrand(id,param));
        }

    };

    const [images, setImages] = useState([]);


    const imageHandler = (e) => {
        setImages(e.target.files);
    };

    console.log(images, '==================>upload images')



    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
                 {loading?<Loader/>:null}
        <form onSubmit={(e) => { e.preventDefault(); handleBrand(e.target) }}>
        <div className="content container-fluid mt-5" >
            <div>
                <h2 className="h1 mb-0 d-flex align-items-center gap-2">
                    <img
                        width={20}
                        src="https://6valley.6amtech.com/public/assets/back-end/img/brand.png"
                        alt=""
                    />
                    Brand Setup
                </h2>

                <div className="card mt-2 p-4">
                    <div className="row ">
                        <div className="col-md-6">
                            <div className="form-group  lang_form" id="en-form">
                                <label htmlFor="name" className="title-color">
                                    Brand Name<span className="text-danger">*</span> (EN)
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    id="name"
                                 
                                    placeholder="name"
                                    required=""
                                    defaultValue={brand?.name}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="name" className="title-color">
                                    Brand Logo<span className="text-danger">*</span>
                                </label>
                                <span className="ml-1 text-info">( Ratio 1:1 )</span>
                                <div className="custom-file text-left" required="">
                                    <input
                                        type="file"
                                        name="image"
                                        id="image"
                                        className="custom-file-input"
                                        accept="/*"
                                        onChange={(e)=>imageHandler(e)}
                                        defaultValue={brand?.image}
                                    />
                                    <label className="custom-file-label" htmlFor="customFileUpload">
                                        Choose File
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                                <div className="form-group">

                                {images.length>0 ?
                                <center>
                                        <div className='position-relative'>
                                            <img src={URL.createObjectURL(images[0])}  className='border rounded-3' />
                                            <span className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white' style={{ left: '98%', lineHeight: '15px', fontSize: 'x-small', cursor: 'pointer' }} onClick={() => {
                                                setImages([])
                                            }}>x</span>
                                        </div>
                                        </center>:
                                <center>
                                        <img
                                            className="upload-img-view"
                                            id="viewer"
                                            src={brand?.image}
                                            alt="image"
                                        />
                                    </center>}
                                    
                                </div>
                            </div>
                        <div className="col-md-6 mb-4">
                            <div className="text-center">
                            {allImg && (
    <div>
      <img src={allImg} alt="Encoded Image" />
    </div>
  )}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <button type="reset" id="reset" className="btn btn-secondary">
                            Reset
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>

                </div>
            </div>
        </div>
        </form>

    </div>
    )
}

export default UpdateBrand;
