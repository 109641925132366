import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { api_url } from '../../ApiUrl'

const AdminWallet = () => {

    const [wallet,setWallet]=useState()
    const [commission,setCommission]=useState()
    const [ship,setShip]=useState()
    const [tax,setTax]=useState()


    useEffect(()=>{

        axios.get(api_url+'/staff/get/1').then(res=>{
            console.log(res.data.data)
            setWallet(res.data.data.wallet)
        })


        axios.get(api_url+'/adminWallet/get').then(res=>{
            console.log(res.data.data)

            const sum = res.data.data.reduce((total, current) => {
                if (current.type === 'commision') {
                    console.log(current)
                  return parseInt(total) + parseInt(current?.amount);
                }
                return total;
              }, 0);


            const sumTax = res.data.data.reduce((total, current) => {
                if (current.type === 'tax') {
                    console.log(current)
                  return parseInt(total) + parseInt(current?.amount);
                }
                return total;
              }, 0);

            const sumShip = res.data.data.reduce((total, current) => {
                if (current.type === 'ship') {
                    console.log(current)
                  return parseInt(total) + parseInt(current?.amount);
                }
                return total;
              }, 0);


            setCommission(sum)
            setTax(sumTax)
            setShip(sumShip)
        })
    },[])






    return (
        <div>
            <div className='mt-5 toppp'>
                <div className="content container-fluid mt-5">
                    <div className="mb-3">
                        <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                            <img
                                width={20}
                                src="https://6valley.6amtech.com/public/assets/back-end/img/inhouse_sale.png"
                                alt=""
                            />
                            Wallet
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card p-3">
                                <div className="card-body">
                                    <h4 className="d-flex align-items-center text-capitalize gap-10 mb-3">
                                        <img
                                            width={20}
                                            className="mb-1"
                                            src="https://6valley.6amtech.com/public/assets/back-end/img/admin-wallet.png"
                                            alt=""
                                        />
                                        Admin wallet
                                    </h4>
                                    <div className="row g-2" id="order_stats">
                                        <div className="col-lg-4">
                                            <div className="card h-100 d-flex justify-content-center align-items-center">
                                                <div className="card-body d-flex flex-column gap-10 align-items-center justify-content-center">
                                                    <img
                                                        width={48}
                                                        className="mb-2"
                                                        src="https://6valley.6amtech.com/public/assets/back-end/img/inhouse-earning.png"
                                                        alt=""
                                                    />
                                                    <h3 className="for-card-count mb-0 fz-24">${wallet}</h3>
                                                    <div className="text-capitalize mb-30">In-house earning</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="row g-2">
                                                <div className="col-md-6">
                                                    <div className="card card-body h-100 justify-content-center">
                                                        <div className="d-flex gap-2 justify-content-between align-items-center">
                                                            <div className="d-flex flex-column align-items-start">
                                                                <h3 className="mb-1 fz-24">${commission}</h3>
                                                                <div className="text-capitalize mb-0">Commission earned</div>
                                                            </div>
                                                            <div>
                                                                <img
                                                                    width={40}
                                                                    className="mb-2"
                                                                    src="https://6valley.6amtech.com/public/assets/back-end/img/ce.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card card-body h-100 justify-content-center">
                                                        <div className="d-flex gap-2 justify-content-between align-items-center">
                                                            <div className="d-flex flex-column align-items-start">
                                                                <h3 className="mb-1 fz-24">${ship}</h3>
                                                                <div className="text-capitalize mb-0">
                                                                    Delivery charge earned
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <img
                                                                    width={40}
                                                                    className="mb-2"
                                                                    src="https://6valley.6amtech.com/public/assets/back-end/img/dce.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card card-body h-100 justify-content-center">
                                                        <div className="d-flex gap-2 justify-content-between align-items-center">
                                                            <div className="d-flex flex-column align-items-start">
                                                                <h3 className="mb-1 fz-24">${tax}</h3>
                                                                <div className="text-capitalize mb-0">Total tax collected</div>
                                                            </div>
                                                            <div>
                                                                <img
                                                                    width={40}
                                                                    className="mb-2"
                                                                    src="https://6valley.6amtech.com/public/assets/back-end/img/ttc.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="card card-body h-100 justify-content-center">
                                                        <div className="d-flex gap-2 justify-content-between align-items-center">
                                                            <div className="d-flex flex-column align-items-start">
                                                                <h3 className="mb-1 fz-24">$0</h3>
                                                                <div className="text-capitalize mb-0">Pending amount</div>
                                                            </div>
                                                            <div>
                                                                <img
                                                                    width={40}
                                                                    className="mb-2"
                                                                    src="https://6valley.6amtech.com/public/assets/back-end/img/pa.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>{" "}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AdminWallet
