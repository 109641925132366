import React,{useEffect} from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd';

import { createshippingCharges, deleteshippingCharges, fetchshippingCharges } from '../../store/shippingChargesSlice';

const ShippingCharges = () => {


    const dispatch = useDispatch()
    const { data: shippingCharges, status } = useSelector((state) => state?.shippingCharges)

    useEffect(() => {
        dispatch(fetchshippingCharges())
    }, [])

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteshippingCharges(id))
            }

        })
    }

    const handleCategory = (values) => {

        if ((document.getElementById(`weight`).value.length === 0)) {
            message.error('Must enter weight')
        }
        else if ((document.getElementById(`price`).value.length === 0)) {
            message.error('Must select price')
        }
        else {

            dispatch(createshippingCharges({
                weight: values.weight.value,
                price: values.price.value,
            }));
        }

    };


    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            <div className="content container-fluid mt-5" >
                <div>
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/deal_of_the_day.png"
                            alt=""
                        />
                        Shipping Charges
                    </h2>


                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">

                            <div className="form-group">
                                <div className="row  lang_form" id="en-form">
                                    <div className="col-md-12">
                                        <label htmlFor="name">Weight</label>
                                        <input
                                            type="text"
                                            name="weight"
                                            className="form-control"
                                            id="weight"
                                            placeholder="Weight"
                                            required=""
                                        />
                                    </div>
                                </div>
                                <div className="row  lang_form" id="en-form">
                                    <div className="col-md-12">
                                        <label htmlFor="name">Price</label>
                                        <input
                                            type="text"
                                            name="price"
                                            className="form-control"
                                            id="price"
                                            placeholder="Price"
                                            required=""
                                        />
                                    </div>
                                </div>

                            </div>




                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>

                        </div>
                    </form>

                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="px-3 py-4">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4 col-md-6 col-lg-8 mb-2 mb-sm-0">
                                            <h5 className="mb-0 text-capitalize d-flex gap-2">
                                                Shipping Charge
                                                <span className="badge badge-soft-dark radius-50 fz-12">4</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id="allOrder"
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Weight</th>
                                                <th>Price</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {shippingCharges?.length>0?shippingCharges && shippingCharges?.map((i, index) => {
                                                return (<>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <span className="font-weight-semibold">{i.weight}</span>
                                                        </td>
                                                        <td>{i.price}</td>

                                                        <td className="text-center">
                                                            <div className="d-flex align-items-center justify-content-center gap-3">

                                                                <button
                                                                    title="Delete"
                                                                    className="btn btn-outline-danger btn-sm"
                                                                    onClick={()=>{onDelete(i.id)}}
                                                                >
                                                                    <i className="bi bi-trash" />
                                                                </button>
                                                                <a
                                                                    className="h-30 d-flex gap-2 align-items-center btn btn-soft-info btn-sm border-info"
                                                                    href="/addProducts"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={9}
                                                                        height={9}
                                                                        viewBox="0 0 9 9"
                                                                        fill="none"
                                                                        className="svg replaced-svg"
                                                                    >
                                                                        <path
                                                                            d="M9 3.9375H5.0625V0H3.9375V3.9375H0V5.0625H3.9375V9H5.0625V5.0625H9V3.9375Z"
                                                                            fill="#00A3AD"
                                                                        />
                                                                    </svg>
                                                                    Add Product
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>)
                                            }):''}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive mt-4">
                                    <div className="px-4 d-flex justify-content-lg-end"></div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>


        </div>
    )
}

export default ShippingCharges
