import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const categorySlice=createSlice({
    name: "category",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setcategory(state,action){
            state.data=action.payload
        },
        getcategory(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setcategory,setStatus,getcategory}=categorySlice.actions
export default categorySlice.reducer




// thunk

export function fetchcategory(){
    return async function fetchcategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/category/get`).then(
                (resp) => {
                    console.log(resp.data)
                    dispatch(setcategory(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createcategory(param){
    return async function createcategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/category/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("category Created")
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatecategory(id,param){
    return async function updatecategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/category/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("category Updated")
                    window.location.href=window.location.origin+"/#/category"


                    console.log(message,"===================================updateeeeeeeeeee")
                    
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletecategory(id){
    return async function deletecategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/category/delete/${id}`).then(res=>{
                console.log(res.data)
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdcategory(id){
    return async function getByIdcategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/category/get/${id}`).then(
                (resp) => {
                    dispatch(getcategory(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
