import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { updatesubSubCategory, getByIdsubSubCategory } from '../../store/subSubCategorySlice';
// import { fetchsubCategory } from '../../store/subCategorySlice';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';

const SubSubCategory = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { data: subSubCategory } = useSelector((state) => state?.subSubCategory);
    // const { data: subCategory } = useSelector((state) => state?.subCategory);

    useEffect(() => {
        // dispatch(fetchcategory())
        dispatch(getByIdsubSubCategory(id));
    }, [])

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable({
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
                bDestroy: true,
                // dom: 'Bfrtip',
                // buttons: ['copy', 'csv', 'print']
            });
        }, 1000);
    });
const [loading, setLoading] = useState(false);
    const handleCategory = (values) => {
       setLoading(true)
            const param = {
                "name": values.name.value,
           
            }
            dispatch(updatesubSubCategory(id, param));
        
    };

    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>{loading?<Loader/>:null}
            <div className="content container-fluid mt-5">
                <div>
                    <h2 className="h1 mb-0 d-flex gap-10">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png"
                            alt=""
                        />
                        Sub Sub Category Setup
                    </h2>

                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">
                            <div className="row">
                                <div className="col-12 form-group  lang_form" id="en-form">
                                    <label className="title-color" htmlFor="exampleFormControlInput1">
                                        Sub sub category Name<span className="text-danger">*</span>
                                        (EN)
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="form-control"
                                        placeholder="New Sub Sub Category"
                                        required=""
                                        defaultValue={subSubCategory?.name}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <label className="title-color">
                                            Sub Category
                                            <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            name="subCategoryId"
                                            id="subCategoryId"
                                            className="form-control"
                                            required=""
                                            defaultValue={subCategory?.subCategoryId}
                                        >
                                            <option value="" disabled="">
                                                Select main Sub Category
                                            </option>
                                            {subCategory?.length > 0 ? subCategory.map((i, index) => {
                                                return (
                                                    <option value={i.id} key={index}>
                                                        {i.name}
                                                    </option>
                                                );
                                            }) : ""}
                                        </select> */}
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>

                        </div>
                    </form>


                </div>
            </div>


        </div>
    )
}

export default SubSubCategory;
