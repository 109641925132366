import React, { useState, useEffect } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd';
import { fetchwithdraw, updatewithdraw, deletewithdraw } from '../../store/withdrawSlice';


const WithdrawPending = () => {



  const [ourId, setOurId] = useState("");

  const dispatch = useDispatch();
  const { data: withdraw, status } = useSelector(
    (state) => state?.withdraw
  );
  console.log(withdraw);
  useEffect(() => {
    dispatch(fetchwithdraw());
  }, []);

  $(document).ready(function () {
    setTimeout(function () {
      $("#allOrder").DataTable({
        pagingType: "full_numbers",
        pageLength: 25,
        processing: true,
        bDestroy: true,
        // dom: 'Bfrtip',
        // buttons: ['copy', 'csv', 'print']
      });
    }, 1000);
  });

  const onDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      onOk: () => {
        dispatch(deletewithdraw(id));
        dispatch(fetchwithdraw());
      },
    });
  };

  const handle = () => {
    dispatch(deletewithdraw());

    dispatch(
      updatewithdraw(ourId, {
        status: document.getElementById(`stati`).value,
      })
    );
  };


  const approveSeller = (id, status) => {
    Modal.confirm({
      title: 'Are you sure you want to approve?',
      onOk: () => {

        const param = {'status': status}

        dispatch(updatewithdraw(id, param))
      }

    })
  }


  return (
    <div className='mt-5 toppp'>
      <div className="content container-fluid">
        <>
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">
                    Update Order Status
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <select className="form-control" id='stati'>
                    <option >Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="denied">Denied</option>


                  </select>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => handle()}
                  >
                    Update
                  </button>

                </div>
              </div>
            </div>
          </div>
        </>
        <div className="mb-3">
          <h2 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
            <img
              width={20}
              src="https://6valley.6amtech.com/public/assets/back-end/img/withdraw-icon.png"
              alt=""
            />
            Withdraw
          </h2>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card p-3">
              <div className=" flex-wrap justify-content-between d-flex">
                <h5 className="mb-3 text-capitalize">
                  Withdraw Pending Table
                  <span className="badge badge-soft-dark radius-50 fz-12 ml-1">
                    3
                  </span>
                </h5>
              </div>
              <table
                id="allOrder"
                style={{ textAlign: "left" }}
                className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light thead-50 text-capitalize">
                  <tr>
                    <th>SL</th>
                    <th>Amount</th>
                    <th>Request time</th>
                    <th>Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {withdraw.data && withdraw.data?.map((item, index) => {
                    if(item?.status===false)
                    { return (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.amount}</td>
                          <td>{item?.createdAt}</td>
                          <td >
                            {item?.status === false ? <label className="badge badge-danger bg-danger ">Pending</label> : <label className="badge badge-success">Pending</label>}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center gap-2">
                              <button
                                title="Approve"
                                className="btn btn-outline-danger btn-sm square-btn"
                                onClick={() => approveSeller(item?.id, item?.status === false ? true : false)}
                              >
                                <i className="bi bi-exclamation-circle" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default WithdrawPending
