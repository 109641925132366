import React,{useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from "antd";
import { createbrand } from '../../store/brandSlice';
import Loader from '../Loader';
const AddBrand = () => {

    const [allImg,setAllImg]=useState('')

    const [loading, setLoading] = useState(false);


      const [selectedFile, setSelectedFile] = useState(null);

      
      
      

    const dispatch = useDispatch();



    const handleBrand = (values) => {
        
        if ((document.getElementById(`name`).value.length === 0)) {
            message.error('Must enter name')
        }
        else {
            setLoading(true)
            const param=new FormData()
            param.append('name',values.name.value)
            param.append('image',values.image.files[0])

            dispatch(createbrand(param));
        }

    };

    const [images, setImages] = useState([]);


    const imageHandler = (e) => {
        setImages(e.target.files);
    };

    console.log(images, '==================>upload images')



    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
              {loading?<Loader/>:null}
            <form onSubmit={(e) => { e.preventDefault(); handleBrand(e.target) }}>
            <div className="content container-fluid mt-5" >
                <div>
                    <h2 className="h1 mb-0 d-flex align-items-center gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/brand.png"
                            alt=""
                        />
                        Brand Setup
                    </h2>

                    <div className="card mt-2 p-4">
                        <div className="row ">
                            <div className="col-md-6">
                                <div className="form-group  lang_form" id="en-form">
                                    <label htmlFor="name" className="title-color">
                                        Brand Name<span className="text-danger">*</span> (EN)
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        id="name"
                                        defaultValue=""
                                        placeholder="name"
                                        required=""
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name" className="title-color">
                                        Brand Logo<span className="text-danger">*</span>
                                    </label>
                                    <span className="ml-1 text-info">( Ratio 1:1 )</span>
                                    <div className="custom-file text-left" required="">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="custom-file-input"
                                            accept="/*"
                                            onChange={(e)=>imageHandler(e)}
                                        />
                                        <label className="custom-file-label" htmlFor="customFileUpload">
                                            Choose File
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                                    <div className="form-group">

                                    {images.length>0 ?
                                    <center>
                                            <div className='position-relative'>
                                                <img src={URL.createObjectURL(images[0])}  className='border rounded-3' />
                                                <span className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white' style={{ left: '98%', lineHeight: '15px', fontSize: 'x-small', cursor: 'pointer' }} onClick={() => {
                                                    setImages([])
                                                }}>x</span>
                                            </div>
                                            </center>:
                                    <center>
                                            <img
                                                className="upload-img-view"
                                                id="viewer"
                                                src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                                alt="image"
                                            />
                                        </center>}
                                        
                                    </div>
                                </div>
                            <div className="col-md-6 mb-4">
                                <div className="text-center">
                                {allImg && (
        <div>
          <img src={allImg} alt="Encoded Image" />
        </div>
      )}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <button type="reset" id="reset" className="btn btn-secondary">
                                Reset
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            </form>

        </div>
    )
}

export default AddBrand
