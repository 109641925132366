import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const subCategorySlice=createSlice({
    name: "subCategory",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setsubCategory(state,action){
            state.data=action.payload
        },
        getsubCategory(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setsubCategory,setStatus,getsubCategory}=subCategorySlice.actions
export default subCategorySlice.reducer




// thunk

export function fetchsubCategory(){
    return async function fetchsubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/subCategory/get`).then(
                (resp) => {
                    console.log(resp.data)
                    dispatch(setsubCategory(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createsubCategory(param){
    return async function createsubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/subCategory/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("subCategory Created")
                    window.location.reload(true)
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatesubCategory(id,param){
    return async function updatesubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/subCategory/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("subCategory Updated")
                 
                    window.location.href=window.location.origin+"/#/subCategory"
                    window.location.reload(true)
                    console.log("===================================updateeeeeeeeeee")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletesubCategory(id){
    return async function deletesubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}/subCategory/delete/${id}`).then(res=>{
                console.log(res.data)
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdsubCategory(id){
    return async function getByIdsubCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/subCategory/get/${id}`).then(
             
                (resp) => {
                    dispatch(getsubCategory(resp.data.data))
                    console.log(resp.data,"yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy getbyid Api")
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
