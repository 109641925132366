import React, { useEffect } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd';
import { createfeatured, deletefeatured, fetchfeatured, updatefeatured } from '../../store/featuredSlice';
import { useNavigate } from 'react-router-dom';

const FeaturedDeal = () => {


    const navigate = useNavigate()

    const dispatch = useDispatch()
    const { data: featured, status } = useSelector((state) => state?.featured)

    useEffect(() => {
        dispatch(fetchfeatured())
    }, [])

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deletefeatured(id))
            }

        })
    }

    const handleCategory = (values) => {

        if ((document.getElementById(`title`).value.length === 0)) {
            message.error('Must enter title')
        }
        else if ((document.getElementById(`startDate`).value.length === 0)) {
            message.error('Must enter start date')
        }
        else if ((document.getElementById(`endDate`).value.length === 0)) {
            message.error('Must enter end date')
        }
        else {

            dispatch(createfeatured({
                title: values.title.value,
                startDate: values.startDate.value,
                endDate: values.endDate.value,
            }));
        }

    };



    const approveFeatured = (id,featured) => {
        Modal.confirm({
            title: 'Are you sure you want to set this feature deal?',
            onOk: () => {

                const param=new FormData();
                param.append('publish', true);
                

                dispatch(updatefeatured(id,param))
            }

        })
    }



    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            <div className="content container-fluid mt-5" >
                <div>
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/featured_deal.png"
                            alt=""
                        />
                        Feature deal
                    </h2>


                    <form onSubmit={(e) => { e.preventDefault(); handleCategory(e.target) }}>
                        <div className="card mt-2 p-4">

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-12  lang_form" id="en-form">
                                        <label htmlFor="name" className="title-color text-capitalize">
                                            Title (EN)
                                        </label>
                                        <input
                                            type="text"
                                            name="title"
                                            id="title"
                                            className="form-control"
                                            placeholder="Ex : LUX"
                                            required=""
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <label htmlFor="name" className="title-color text-capitalize">
                                            Start date
                                        </label>
                                        <input
                                            type="date"
                                            name="startDate"
                                            id="startDate"
                                            required=""
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label htmlFor="name" className="title-color text-capitalize">
                                            End date
                                        </label>
                                        <input type="date" name="endDate" id="endDate" className="form-control" />
                                    </div>
                                </div>
                            </div>




                            <div className="d-flex flex-wrap gap-2 justify-content-end">
                                <button type="reset" id="reset" className="btn btn-secondary">
                                    Reset
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>

                        </div>
                    </form>

                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="card p-3">
                                <div className="table-responsive">
                                    <table
                                        id="allOrder"
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Title</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Active / Expired</th>
                                                <th>Status</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {featured?.length > 0 ? featured && featured?.map((i, index) => {
                                                return (<>
                                                    <tr>
                                                        <th>{index + 1}</th>
                                                        <td>{i.title}</td>
                                                        <td>{new Date(i?.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}</td>
                                                        <td>{new Date(i?.endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}</td>
                                                        <td>
                                                            <span className="badge badge-soft-success"> Active </span>
                                                        </td>
                                                        <td>
                                                            <label className="switcher">
                                                                {i?.publish === true ? <input
                                                                    className="switcher_input"
                                                                    type="checkbox"
                                                                    onClick={() => approveFeatured(i?.id, i?.publish === false ? true : false)}
                                                                    defaultChecked
                                                                /> : <input
                                                                    className="switcher_input"
                                                                    type="checkbox"
                                                                    onClick={() => approveFeatured(i?.id, i?.publish === false ? true : false)}

                                                                />}
                                                                <span className="switcher_control" />
                                                            </label>
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="d-flex align-items-center justify-content-center gap-3">
                                                                <button
                                                                    title="Delete"
                                                                    className="btn btn-outline-danger btn-sm"
                                                                    onClick={() => { onDelete(i.id) }}
                                                                >
                                                                    <i className="bi bi-trash" />
                                                                </button>
                                                                <a
                                                                    className="h-30 d-flex gap-2 align-items-center btn btn-soft-info btn-sm border-info"
                                                                    onClick={() => navigate(`/featureDealProduct/${i?.id}`)}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={9}
                                                                        height={9}
                                                                        viewBox="0 0 9 9"
                                                                        fill="none"
                                                                        className="svg replaced-svg"
                                                                    >
                                                                        <path
                                                                            d="M9 3.9375H5.0625V0H3.9375V3.9375H0V5.0625H3.9375V9H5.0625V5.0625H9V3.9375Z"
                                                                            fill="#00A3AD"
                                                                        />
                                                                    </svg>
                                                                    Add Product
                                                                </a>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>)
                                            }) : ""}
                                        </tbody>
                                    </table>

                                </div>
                                <div className="table-responsive mt-4">
                                    <div className="px-4 d-flex justify-content-lg-end"></div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>


        </div>
    )
}

export default FeaturedDeal
