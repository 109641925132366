import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd';
import axios from 'axios';
import { api_url } from '../../ApiUrl';
import { fetchproduct } from '../../store/productSlice';

const StockProduct = () => {

  const dispatch = useDispatch()
  const { data: product, status } = useSelector((state) => state?.product);
  console.log(product, "=============all products===========")

  useEffect(() => {
    dispatch(fetchproduct())
  }, [])

  const [newQuantity, setNewQuantity] = useState("")

  const handleUpdateQuantity = (id, quantity) => {
    try {

      const param = {
        quantity: quantity
      }
      axios.put(`${api_url}/product/update/${id}`, param)
        .then(res => {
          console.log(res.data)
          if (res.data.status === "ok") {
            message.success("Quantity Update Successfully")
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <div className="content container-fluid">
        <div className="mt-3 pt-5">
          <h2 className="h1 mb-2 pt-4 d-flex align-items-center gap-2 text-capitalize">
            <img
              src=""
              alt=""
            />
            Stock limit products list
            <span className="badge badge-soft-dark radius-50 fz-14">1</span>
          </h2>
          <p>The products are shown in this list which quantity is below 10</p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="px-3 py-4">
                <div className="row justify-content-between align-items-center gy-2">
                  <div className="col-12 mt-1 col-md-6 col-lg-4">
                   
                      <div className="input-group input-group-merge input-group-custom">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className="tio-search" />
                          </div>
                        </div>
                        <input
                          id="datatableSearch_"
                          type="search"
                          name="search"
                          className="form-control"
                          placeholder="Search by Product Name"
                          aria-label="Search orders"
                          defaultValue=""
                          required=""
                        />
                        <button type="submit" className="btn btn--primary">
                          Search
                        </button>
                      </div>
                    
                  </div>
                  <div className="col-12 mt-1 col-md-6 col-lg-3">
                    <select
                      name="qty_ordr_sort"
                      className="form-control"
                    >
                      <option value="default">Default sort</option>
                      <option value="quantity_asc">
                        Quantity sort by (low to high)
                      </option>
                      <option value="quantity_desc">
                        Quantity sort by (high to low)
                      </option>
                      <option value="order_asc">Order sort by (low to high)</option>
                      <option value="order_desc">Order sort by (high to low)</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table
                  id="datatable"
                  style={{ textAlign: "left" }}
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                >
                  <thead className="thead-light thead-50 text-capitalize">
                    <tr>
                      <th>SL</th>
                      <th>Product Detail</th>
                      <th>Product Type</th>
                      <th>Purchase price</th>
                      <th>Selling price</th>
                      <th>Status</th>
                      <th className="text-center">Quantity</th>
                      <th className="text-center">Orders</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product?.length > 0 ? product?.map((item, index) => {
                      if (item?.staffId && localStorage?.getItem("staffId")) {
                        if ((item?.staffId).toString() === (localStorage?.getItem("staffId"))) {
                          return (
                            <>
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  <a
                                    className="media align-items-center gap-2"
                                  >
                                    <img
                                      src={item?.productImage}
                                      className="avatar border"
                                      alt=""
                                    />
                                    <span className="media-body title-color hover-c1">
                                      {item?.name?.slice(0,5)}...
                                    </span>
                                  </a>
                                </td>
                                <td>{item?.productType}</td>
                                <td>{item?.purchasePrice}</td>
                                <td>{item?.unitPrice}</td>
                                <td>{item?.status ? "Approved" : "Pending"}</td>
                                <td>
                                  <div className="d-flex align-items-center justify-content-center">
                                    {item?.quantity}
                                    <button
                                      className="btn btn-primary ms-1 btn-sm btn_quantity"
                                      id={10}
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#update-quantity"
                                      title="Update quantity"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={() => {
                                        localStorage.setItem("proId", item?.id)
                                        localStorage.setItem("proQ", item?.quantity)
                                      }}
                                    >
                                      +
                                    </button>
                                    {/* Model Quantity Start */}
                                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                      <div class="modal-dialog">
                                        <div class="modal-content">
                                          <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="exampleModalLabel">Update Quantity</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                          </div>
                                          <div class="modal-body">
                                            <input
                                              type='number'
                                              className='quantityModelInput'
                                              defaultValue={item?.quantity}
                                              onChange={(e) => setNewQuantity(e.target.value)}
                                            />
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button
                                              type="button"
                                              class="btn btn-primary"
                                              data-bs-dismiss="modal"
                                              onClick={(e) => {
                                                e.preventDefault()
                                                handleUpdateQuantity(localStorage.getItem("proId"), newQuantity)
                                              }}
                                            >
                                              Update Quantity
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* Model Quantity End */}
                                  </div>
                                </td>
                                <td className="text-center">0</td>
                              </tr>
                            </>)
                        }
                      }
                    }) : null}
                  </tbody>
                </table>

              </div>
              <div className="table-responsive mt-4">
                <div className="px-4 d-flex justify-content-lg-end"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}


export default StockProduct