import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from "antd";
import { createBanner ,updateBanner,getByIdBanner} from '../../store/bannerSlice';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';

const UpdateBanner = () => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const {id}=useParams()
    const { data: banner, status } = useSelector((state) => state?.banner)
    useEffect(() => {
      
        dispatch(getByIdBanner(id));
        const defaultValue = banner?.type;

    
    }, [])

    const handleBanner = (values) => {

        if ((document.getElementById(`bannerType`).value.length === 0)) {
            message.error('Must enter banner type')
        }
        else if (document.getElementById(`type`).value.length === 0) {
            message.error('Must enter type')
        }
        else {
setLoading(true)
            const param=new FormData()
            param.append('bannerType',values.bannerType.value)
            param.append('type',values.type.value)
            param.append('image',values.image.files[0])

     
            dispatch(updateBanner(id,param));
        }

    };


    const [images, setImages] = useState([]);


    const imageHandler = (e) => {
        setImages(e.target.files);
    };

    console.log(images, '==================>upload images')



    return (
        <div className='mt-5 toppp'>{loading?<Loader/>:null}
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                        <img
                            width={20}
                            src="https://6valley.6amtech.com/public/assets/back-end/img/banner.png"
                            alt=""
                        />
                        Update Banner
                    </h2>
                </div>
                <div
                    className="row pb-4 d--none"
                    id="main-banner"
                    style={{ textAlign: "left" }}
                >
                    <form onSubmit={(e) => { e.preventDefault(); handleBanner(e.target) }}>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-0 text-capitalize">Banner form</h5>
                                </div>
                                <div className="card-body">
                                    
                                        <input
                                            type="hidden"
                                            name="_token"
                                            defaultValue="XZ0CgkdejXM9nzfRaBH3Puq0HVKIHKilR8sqcjoq"
                                        />{" "}
                                        <div className="row">
                                            <div className="col-md-6">
                                                {/* <div className="form-group">
                                                <input type="hidden" id="id" name="id" />
                                                <label htmlFor="name" className="title-color text-capitalize">
                                                    Banner URL
                                                </label>
                                                <input
                                                    type="text"
                                                    name="url"
                                                    className="form-control"
                                                    id="url"
                                                    required=""
                                                />
                                            </div> */}
                                                <div className="form-group">
                                                    <label htmlFor="name" className="title-color text-capitalize">
                                                        Banner type
                                                    </label>
                                                    <select
                                                        className="js-example-responsive form-control w-100 select2-hidden-accessible"
                                                        name="bannerType"
                                                        id="bannerType"
                                                        required=""
                                                        data-select2-id={1}
                                                        tabIndex={-1}
                                                        aria-hidden="true"
                                                        defaultValue={banner?.bannerType}
                                                    >
                                                        <option value="Main Banner" data-select2-id={3}>
                                                            Main Banner
                                                        </option>
                                                        <option value="Footer Banner">Footer Banner</option>
                                                        <option value="Popup Banner">Popup Banner</option>
                                                        <option value="Main Section Banner">Main Section Banner</option>
                                                    </select>
                                                    <span
                                                        className="select2 select2-container select2-container--default"
                                                        dir="ltr"
                                                        data-select2-id={2}
                                                        style={{ width: "auto" }}
                                                    >
                                                        <span className="selection">
                                                            <span
                                                                className="select2-selection select2-selection--single"
                                                                role="combobox"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                tabIndex={0}
                                                                aria-disabled="false"
                                                                aria-labelledby="select2-banner_type-nu-container"
                                                            >
                                                                <span
                                                                    className="select2-selection__rendered"
                                                                    id="select2-banner_type-nu-container"
                                                                    role="textbox"
                                                                    aria-readonly="true"
                                                                    title="Main Banner"
                                                                >
                                                                </span>
                                                                <span className="select2-selection__arrow" role="presentation">
                                                                    <b role="presentation" />
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span className="dropdown-wrapper" aria-hidden="true" />
                                                    </span>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="resource_id" className="title-color text-capitalize">
                                                        Resource type
                                                    </label>
                                                    <select
                                                        onchange="display_data(this.value)"
                                                        className="js-example-responsive form-control w-100 select2-hidden-accessible"
                                                        name="type"
                                                        id="type"
                                                        required=""
                                                        data-select2-id={4}
                                                        tabIndex={-1}
                                                        aria-hidden="true"
                                                        defaultValue={banner?.type}
                                                    >
                                                        <option value="product" data-select2-id={6}>
                                                            Product
                                                        </option>
                                                        <option value="category">Category</option>
                                                        <option value="shop">Shop</option>
                                                        <option value="brand">Brand</option>
                                                    </select>
                                                    <span
                                                        className="select2 select2-container select2-container--default"
                                                        dir="ltr"
                                                        data-select2-id={5}
                                                        style={{ width: "auto" }}
                                                    >
                                                        <span className="selection">
                                                            <span
                                                                className="select2-selection select2-selection--single"
                                                                role="combobox"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                tabIndex={0}
                                                                aria-disabled="false"
                                                                aria-labelledby="select2-resource_type-47-container"
                                                            >
                                                                <span
                                                                    className="select2-selection__rendered"
                                                                    id="select2-resource_type-47-container"
                                                                    role="textbox"
                                                                    aria-readonly="true"
                                                                    title="Product"
                                                                >
                                                                </span>
                                                                <span className="select2-selection__arrow" role="presentation">
                                                                    <b role="presentation" />
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span className="dropdown-wrapper" aria-hidden="true" />
                                                    </span>
                                                </div>
                                                
                                            </div>
                                            <div className="col-md-6 mt-5 mt-lg-0" >
                                            <div className="col-lg-6 mt-4 mt-lg-0 from_part_2">
                                    <div className="form-group">

                                    {images.length>0 ?
                                    <center>
                                            <div className='position-relative'>
                                                <img src={URL.createObjectURL(images[0])}  className='border rounded-3' />
                                                <span className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white' style={{ left: '98%', lineHeight: '15px', fontSize: 'x-small', cursor: 'pointer' }} onClick={() => {
                                                    setImages([])
                                                }}>x</span>
                                            </div>
                                            </center>:
                                    <center>
                                            <img
                                                className="upload-img-view"
                                                id="viewer"
                                                src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                                alt="image"
                                            />
                                        </center>}
                                        
                                    </div>
                                </div>
                                                <label htmlFor="name" className="title-color text-capitalize">
                                                    Image
                                                </label>
                                                <span className="text-info">( Ratio 4:1 )</span>
                                                <div className="custom-file text-left">
                                                    <input
                                                        type="file"
                                                        name="image"
                                                        id="image"
                                                        className="custom-file-input"
                                                        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                                        onChange={(e)=>imageHandler(e)}
                                                        defaultValue={banner?.image}
                                                    />
                                                    <label
                                                        className="custom-file-label title-color"
                                                        htmlFor="mbimageFileUploader"
                                                    >
                                                     
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3 d-flex justify-content-end flex-wrap gap-3">
                                            <button className="btn btn-secondary cancel px-4" type="reset">
                                                Reset
                                            </button>
                                            <button id="add" type="submit" className="btn btn-primary px-4">
                                                Save
                                            </button>
                                            <button id="update" className="btn btn--primary d--none text-white">
                                                Update
                                            </button>
                                        </div>
                                    
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

            </div>


        </div>
    )
}

export default UpdateBanner