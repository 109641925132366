import React, { useEffect } from 'react'
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { deleteproduct, fetchproduct, updateproduct } from '../../store/productSlice';


const DeniedProducts = () => {

    const dispatch = useDispatch()
    const {data: product, status} = useSelector((state) => state?.product)


    useEffect(() => {
        dispatch(fetchproduct())
    }, [])

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable(
                {
                    pagingType: 'full_numbers',
                    pageLength: 25,
                    processing: true,
                    bDestroy: true,
                    // dom: 'Bfrtip',
                    // buttons: ['copy', 'csv', 'print']
                }
            );
        },
            1000
        );
    });


    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteproduct(id))
            }

        })
    }


    const approveSeller = (id,status) => {
        Modal.confirm({
            title: 'Are you sure you want to approve?',
            onOk: () => {

                const param=new FormData();
                param.append('status', status);
                

                dispatch(updateproduct(id,param))
            }

        })
    }


    const approveFeatured = (id,featured) => {
        Modal.confirm({
            title: 'Are you sure you want to set as featured?',
            onOk: () => {

                const param=new FormData();
                param.append('featured', featured);
                

                dispatch(updateproduct(id,param))
            }

        })
    }


    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px !important' }}>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/inhouse-product-list.png"
                            alt=""
                        />
                        Denied Product List
                        <span className="badge badge-soft-dark radius-50 fz-14 ml-1">2</span>
                    </h2>

                </div>
                <div className="row mt-20">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                <table
                                        id="allOrder"
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Product Name</th>
                                                <th className="text-right">Product Type</th>
                                                <th className="text-right">Purchase price</th>
                                                <th className="text-right">Selling price</th>
                                                <th className="text-center">Show as featured</th>
                                                <th className="text-center">Active Status</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {product && product?.map((i, index) => {
                                            if(i?.addedAs==='seller' && i?.status===false){
                                            return (<>
                                            <tr>
                                                <th scope="row">{index+1}</th>
                                                <td>
                                                    <a
                                                        className="media align-items-center gap-2"
                                                    >
                                                        <img
                                                            src={i?.productImage}
                                                            className="avatar border"
                                                            alt=""
                                                        />
                                                        <span className="media-body title-color hover-c1">{i?.name?.slice(0,5)}...</span>
                                                    </a>
                                                </td>
                                                <td className="text-right">{i?.productType}</td>
                                                <td className="text-right">{i?.purchasePrice}</td>
                                                <td className="text-right">{i?.unitPrice}</td>
                                                <td className="text-center">
                                                    <label className="mx-auto switcher">
                                                    {i?.featured===true?<input
                                                            className="switcher_input"
                                                            type="checkbox"
                                                            onClick={()=>approveFeatured(i?.id,i?.featured===false?true:false)}
                                                            defaultChecked
                                                        />:<input
                                                        className="switcher_input"
                                                        type="checkbox"
                                                        onClick={()=>approveFeatured(i?.id,i?.featured===false?true:false)}
                                                        
                                                    />}
                                                        <span className="switcher_control" />
                                                    </label>
                                                </td>
                                                <td className="text-center">
                                                {i?.status===false?<label className="badge badge-danger bg-danger ">Pending</label>:<label className="badge badge-success">Active</label>}
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-center gap-2">
                                                    <button
                                                        title="Approve"
                                                        className="btn btn-outline-danger btn-sm square-btn"
                                                        onClick={()=>approveSeller(i?.id,i?.status===false?true:false)}
                                                    >
                                                        <i className="bi bi-exclamation-circle" />
                                                    </button>
                                                        {/* <button
                                                            className=" btn btn-outline-dark  square-btn btn-sm mr-1"
                                                            title="Edit"
                                                        >
                                                            <i className="bi bi-pencil " />
                                                        </button> */}
                                                        <button
                                                        onClick={()=>onDelete(i?.id)}
                                                            className=" btn btn-outline-danger square-btn btn-sm mr-1"
                                                            title="Invoice"
                                                            >
                                                            <i className="bi bi-trash" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                           
                                            </>)
                                            }
                                        })}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DeniedProducts
